import { useEffect, useState } from "react";
import "./Category.scss";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import CategoryModel from "./categoryModel/CategoryModel";
import { ConfirmChange } from "../../Utills/ConfirmAlerts";
import NoData from "../../Utills/NoData/NoData";
import Loader from "../../Utills/Loader/Loader";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

export default function Category() {
  const axiosInstance = useApiService();
  const [categoryList, setCategoryList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [type, setType] = useState<any>(null);
  const [editCategoryName, setEditCategoryName] = useState(null);
  const [loading, setLoading] = useState(false);

  // Delete Item
  const StatusChangeHandler = async (item: any) => {
    const response = await axiosInstance.patch(`admin/jobCategory`, {
      isActive: !item.isActive,
      id: item._id,
    });
    if (response.status == 200) {
      if (response?.data?.status === 1) {
        ToastSuccess(response?.data?.message);
        getCategoryList();
      } else {
        ToastFailure(response?.data?.message);
      }
    }
  };

  // Get Category list
  const getCategoryList = async () => {
    setLoading(true);
    const response = await axiosInstance.get(`admin/jobCategory`);
    setLoading(false);
    setCategoryList(response?.data?.data);
  };

  // Edit Category
  const editCategory = (data: any) => {
    setEditCategoryName(data);
    setType("update");
    setModalShow(true);
  };

  // Add Category
  const addCategory = () => {
    setType("add");
    setModalShow(true);
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <>
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <div className="CategorySec">
          <div className="Header">
            <div className="categoryHeader">
              <div>
                <a href="" className="navbarBrand">
                  CATEGORIES
                </a>
              </div>
              <div className="CategoryHeaderBtn">
                <button
                  type="button"
                  className="Addnew"
                  onClick={() => addCategory()}
                >
                  <i className="fa-regular fa-plus CategoryPlus" />
                  <span> Add New</span>
                </button>
              </div>
            </div>
          </div>
          <div className="CategoryContent">
            {categoryList?.length > 0 ? (
              categoryList.map((list: any, index: number) => {
                return (
                  <div className="CategoryDetails" key={index}>
                    <div
                      className="CategoryTitle"
                      onClick={() => editCategory(list)}
                    >
                      <h3>{list.categoryName}</h3>
                    </div>
                    <div>
                      <button
                        className="EditButton"
                        onClick={() =>
                          ConfirmChange(
                            "Are you sure ?",
                            list,
                            StatusChangeHandler
                          )
                        }
                      >
                        <span className="deleteText ">
                          {list.isActive ? "Disable" : "Activate"}
                        </span>
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <NoData content="Category not found." />
            )}
          </div>

          {modalShow && (
            <div>
              <CategoryModel
                type={type}
                show={modalShow}
                onHide={() => {
                  setModalShow(false);
                  setEditCategoryName(null);
                }}
                data={editCategoryName}
                getCategoryList={getCategoryList}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
