import { useState, useEffect, useRef } from "react";
import "./Packages.style.scss";

import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import del from "../../Assets/SideBar-Image/del.png";
import PackagesHeader from "../../Component/Header/PackagesHeader";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
// import { sortByName } from "../../Utills/utils";
import { ConfirmDelete } from "../../Utills/ConfirmAlerts";
import NoData from "../../Utills/NoData/NoData";
import Loader from "../../Utills/Loader/Loader";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function Packages() {
  const axiosInstance = useApiService();
  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();
  const [packages, setPackages] = useState([]);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState<any>("name");
  const pageSize = 10;

  const getPackages = () => {
    setLoading(true);
    axiosInstance
      .get(
        `admin/packages?currentPage=${page}&pageSize=${pageSize}&search=${search}&sortBy=${sortBy}`
      )
      .then((response) => {
        setLoading(false);
        setTotal(response?.data?.totalPackages);
        setPackages(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const dragPackages = async (data: any) => {
    await axiosInstance
      .put(`admin/package-sequence`, {
        packageIds: data,
      })
      .then((response) => {
        ToastSuccess(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deletePackage = async (_id: any) => {
    setLoading(true);
    await axiosInstance
      .delete(`admin/package?_id=${_id}`, {
        params: { _id },
      })
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          ToastSuccess("Successfully Deleted.");
          getPackages();
        } else {
          ToastFailure(response?.data?.error);
        }
        setSearch("");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setSearch("");
      });
  };

  const dragStart = (e: any, position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    const copyListItems = [...packages];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setPackages(copyListItems);
    dragPackages(
      copyListItems.map((item: any, index) => ({
        sequenceNumber: index + 1,
        _id: item._id,
      }))
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getPackages();
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [search, page, sortBy]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  const onChangeSorting = (data: any) => {
    setPage(1);
    setSortBy(data);
  };

  return (
    <>
      <PackagesHeader
        setSearch={setSearch}
        onChangeHandler={onChangeSorting}
        searchValue={search}
        sortValue={sortBy}
      />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <div className="packages-container TopCommon MainClass">
          <div className="packages">
            {packages?.length > 0 ? (
              packages.map((item: any, index) => {
                const { title } = item;
                return (
                  <div
                    className="packages-Box"
                    onDragStart={(e) => dragStart(e, index)}
                    onDragEnter={(e) => dragEnter(e, index)}
                    onDragEnd={drop}
                    key={index}
                    draggable
                  >
                    <ul className="content-item">
                      <li className="pakages-item">
                        <div className="content-Name">
                          <span className="moveicon">
                            <i className="fa-sharp fa-solid fa-ellipsis-vertical" />
                            <i className="fa-sharp fa-solid fa-ellipsis-vertical" />
                          </span>
                          <div className="heading-content-title">
                            <span className="packages-title">
                              <Link
                                to={`/packages-child/${item._id}`}
                                className="include-parking"
                              >
                                <div className="Packages-contents">
                                  <div className="title">{title}</div>
                                </div>
                              </Link>
                            </span>
                          </div>
                        </div>
                        <button
                          className="delete-btn"
                          onClick={() => ConfirmDelete(item._id, deletePackage)}
                        >
                          <a className="Delete-packages">
                            <img src={del} className="fa-solid fa-trash-can" />
                            <span className="delete-team-member">
                              DELETE PACKAGE
                            </span>
                          </a>
                        </button>
                      </li>
                    </ul>
                  </div>
                );
              })
            ) : (
              <NoData content="No package found." />
            )}
            {total > pageSize && (
              <Pagination
                activePage={page}
                itemsCountPerPage={pageSize}
                totalItemsCount={total}
                onChange={(page) => setPage(page)}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Packages;
