import "./Header.style.scss";
import { SidebarHeading } from "../../Store/Store";

function NotificationHeader() {
  return (
    <div className="header">
      <nav className="navbar">
        <div className="content-Event">
          <div className="headding">
            <a className="navbarBrand">{SidebarHeading[4]}</a>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NotificationHeader;
