import React from "react";
import "../Header.style.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import backimage from "../../../Assets/SideBar-Image/ic-header-back.png";

function ChiledHeader(props: any) {
  const { isAddEvent, eventInfo, setIsEventPublish } = props;
  const eventPublishOption = [
    { label: "DRAFT", value: 0 },
    { label: "PUBLISHED", value: 1 },
  ];
  return (
    <div className="header EventChildHeader">
      <nav className="navbar-child">
        <div className="content-Child-Headers">
          <div className="headding-child-Headers">
            <div className="child-Header-content">
              <div className="backimage">
                <Link to="/events">
                  <img src={backimage} alt="" className="back-arrow" />
                </Link>
              </div>
              <a className="navbarBrand-childeheaders">
                {isAddEvent ? "ADD EVENT" : eventInfo?.title}
                {!isAddEvent && eventInfo?.updatedAt && (
                  <p className="lastEditeby">
                    Last edited:
                    {eventInfo?.updatedAt
                      ? moment(eventInfo.updatedAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )
                      : ""}
                    by {eventInfo.updatedBy}
                  </p>
                )}
              </a>
            </div>
            <div className="dropdown-childHeader">
              <div className="draft-public">
                <select
                  name="selector-childHeader"
                  className="selected-items"
                  onChange={(e) => setIsEventPublish(e.target.value)}
                  value={eventInfo.isPublished}
                >
                  {eventPublishOption.map((list, index) => (
                    <option key={index} value={list.value}>
                      {list.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default ChiledHeader;
