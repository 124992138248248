import { useState, useEffect } from "react";

import "./Team.style.scss";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import del from "../../Assets/SideBar-Image/del.png";
import TeamHeader from "../../Component/Header/TeamHeader";
import { ConfirmDelete } from "../../Utills/ConfirmAlerts";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import NoData from "../../Utills/NoData/NoData";
import Loader from "../../Utills/Loader/Loader";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function Team() {
  const axiosInstance = useApiService();
  const [loading, setLoading] = useState(true);
  const [headings, setHeadings] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();
  const pageSize = 10;

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getTeamsData();
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [page, search]);

  // get teams data
  const getTeamsData = async () => {
    setLoading(true);
    const apiResponse = await axiosInstance.get(`admin/members`, {
      params: { currentPage: page, pageSize: 10, search },
    });
    setLoading(false);
    setHeadings(apiResponse?.data?.data ?? []);
    setTotal(apiResponse?.data?.totalUsers ?? 0);
  };

  // delete team
  const deleteTeam = async (_id: any) => {
    setLoading(true);
    await axiosInstance
      .delete(`admin/member`, {
        params: { _id },
      })
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          navigate("/team", { replace: true });
          ToastSuccess("Successfully Deleted.");
          if (headings.length === 1) {
            setPage(() => page - 1);
          }
          getTeamsData();
        } else {
          ToastFailure(response?.data?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const onNavigate = (item: any) => {
    const { firstName, lastName, email, _id, updatedAt, updatedBy } = item;
    navigate("/teamChild", {
      state: { firstName, lastName, email, _id, updatedAt, updatedBy },
    });
  };

  return (
    <>
      <TeamHeader searchContent={search} setSearchContent={setSearch} />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <div className="Team-container ">
          <div className="Team MainClass">
            {headings?.length > 0 ? (
              headings.map((item, index) => {
                const { email, firstName, lastName, _id } = item;
                return (
                  <div className="Team-Box" key={index}>
                    <ul
                      className="content-item"
                      onClick={() => onNavigate(item)}
                    >
                      <li className="team-pakages-pakages">
                        <div className="heading-content">
                          <span className="packages-title">
                            <a className="Team-include">
                              <div className="Packages-contents">
                                <div className="title">
                                  <span className="Team-Name">
                                    {`${firstName} ${lastName}`}
                                  </span>
                                </div>
                                <span className="email"> {email} </span>
                              </div>
                            </a>
                          </span>
                        </div>
                        <button
                          className="delete-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            ConfirmDelete(_id, deleteTeam);
                          }}
                        >
                          <img src={del} className="fa-solid fa-trash-can" />
                          <span className="delete-team-member">
                            DELETE TEAM MEMBER
                          </span>
                        </button>
                      </li>
                    </ul>
                  </div>
                );
              })
            ) : (
              <NoData content="No team member found." />
            )}
            {total > pageSize && (
              <Pagination
                activePage={page}
                itemsCountPerPage={pageSize}
                totalItemsCount={total}
                onChange={(page) => setPage(page)}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Team;
