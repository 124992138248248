import "../Faq.style.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { ToastFailure, ToastSuccess } from "../../../Share/toast/ToastMsg";
import useApiService from "../../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function FaqChildModal(props: any) {
  const axiosInstance = useApiService();
  const [childModalFaq, setChildModalFaq] = useState({
    answer: "",
    question: "",
    sectionId: props.id,
  });

  const addFaqchildModal = () => {
    if (childModalFaq.question.length === 0) {
      ToastFailure("Please enter questions");
      return;
    }
    if (childModalFaq.answer.length == 0) {
      ToastFailure("Please enter the Answer");
      return;
    }
    axiosInstance
      .post(`admin/question`, {
        answer: childModalFaq.answer,
        question: childModalFaq.question,
        sectionId: childModalFaq.sectionId,
      })
      .then((response) => {
        props.onHide();
        ToastSuccess(response.data.message);
        props.getAllQuestions();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setChildModalFaq({
      answer: "",
      question: "",
      sectionId: props.id,
    });
  }, [props.onHide]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Question/Answer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="faqpopup-contant">
          <div className="faqPopup-container">
            <div className="section-Name">
              <p className="section-title">Question</p>
              <input
                type="text"
                name=""
                className="section-input-fields"
                onChange={(e) =>
                  setChildModalFaq({
                    ...childModalFaq,
                    question: e.target.value,
                  })
                }
              />
            </div>
            <div className="section-Name-child">
              <p className="section-title-child">Answer</p>
              <input
                type="text"
                name=""
                className="section-input-fields-child"
                onChange={(e) =>
                  setChildModalFaq({
                    ...childModalFaq,
                    answer: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="faq-cancel-btn">
          CANCEL
        </Button>
        <Button onClick={() => addFaqchildModal()} className="Faq-save-btn">
          CREATE
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FaqChildModal;
