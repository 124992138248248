import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import backimage from "../../../Assets/SideBar-Image/ic-header-back.png";

function SponserChildHeader(props: any) {
  return (
    <div className="header">
      <nav className="navbar-child">
        <div className="content-Child-Headers">
          <div className="bannerChildHeader">
            <div className="backimage">
              <Link to="/sponsors">
                <img src={backimage} alt="" className="back-arrow" />
              </Link>
            </div>
            <div className="HeaderWithDate">
              <a className="navbarBrand-childeheaders">{props.name}</a>
              {props.name != "ADD SPONSOR" && (
                <p className="lastEditeby">
                  Last edited: {moment(props.updatedAt).format("Do MMM YY LT")}{" "}
                  {props.updatedBy}
                </p>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default SponserChildHeader;
