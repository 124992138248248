import { useState } from "react";
import logo from "../../Assets/SideBar-Image/logo.png";
import "./Login.style.scss";
import { useNavigate } from "react-router-dom";
import { DefaultRoute } from "../../router/RouthPath";
import { ToastFailure } from "../../Share/toast/ToastMsg";
import { isEmail } from "../../Utills/utils";
import PropTypes from "prop-types";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

interface Iprops {
  setSigninStatus: any;
}

const Login: React.FC<Iprops> = ({ setSigninStatus }) => {
  const axiosInstance = useApiService();
  const [formValues, setFormValues] = useState({ email: "", password: "" });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (formValues.email.length === 0) {
      ToastFailure("Please add email");
      return;
    }
    if (formValues.password.length === 0) {
      ToastFailure("Please add password");
      return;
    }
    if (!isEmail(formValues.email)) {
      ToastFailure("Please Enter the valid email");
      return;
    }

    axiosInstance
      .post(`login`, {
        email: `${formValues.email}`,
        password: `${formValues.password}`,
      })
      .then((response) => {
        if (response.data.status === 1) {
          localStorage.setItem("AUTHTOKEN", response.data.token);
          setSigninStatus(true);
          if (sessionStorage.getItem("previousUrl")) {
            navigate(`${sessionStorage.getItem("previousUrl")}`);
          } else {
            navigate(DefaultRoute, { replace: true });
          }
        } else {
          ToastFailure(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        ToastFailure(error.response.maass);
      });
  };

  const navigate = useNavigate();

  return (
    <div className="loginform">
      <div className="bgimage">
        <div className="logo">
          <img className="waterfrontlogo" src={logo} alt="/" />

          <div className="logiform">
            <form className="formsubmistion" onSubmit={handleSubmit} noValidate>
              <div className="LoginSection">
                <div className="input-type-email">
                  <input
                    type="email"
                    autoComplete="off"
                    placeholder="LOGIN"
                    value={formValues.email}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-type-password">
                  <input
                    type="password"
                    autoComplete="off"
                    placeholder="PASSWORD"
                    value={formValues.password}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        password: e.target.value,
                      })
                    }
                  />
                </div>
                <p
                  className="ForgotHeading"
                  onClick={() => navigate("/forgotpassword")}
                >
                  Forgot Password
                </p>
                <button type="submit" className="btn-primary">
                  LOGIN
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  setSigninStatus: PropTypes.any.isRequired,
};
export default Login;
