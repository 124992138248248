import React from "react";
import "./Header.style.scss";
import { useNavigate } from "react-router-dom";
import { SidebarHeading } from "../../Store/Store";
interface Iprops {
  setSearch: any;
  onChangeHandler: any;
  searchValue: any;
  sortValue: any;
}
const PackagesHeader: React.FC<Iprops> = ({
  setSearch,
  onChangeHandler,
  searchValue,
  sortValue,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="header PackageHeader">
        <nav className="navbar">
          <div className="content-Event">
            <div className="headding">
              <a className="navbarBrand">{SidebarHeading[2]}</a>
              <div className="dropdown d-flex">
                <span className="sort">Sort</span>
                <span className="by">by:</span>
                <div className="datepic">
                  <select
                    value={sortValue}
                    className="selector"
                    onChange={(e) => onChangeHandler(e.target.value)}
                  >
                    <option value="date">Date</option>
                    <option value="name">Name</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="form HideForm">
              <div className="packageHeaderAlign">
                <div className="searchBar">
                  <input
                    className="search"
                    type="input"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchValue}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <a className="searchicone">
                    <i className="fa-solid fa-magnifying-glass" />
                  </a>
                </div>
                <div className="addNewBtn HideForm">
                  <button
                    className="Add-new-Packages"
                    type="button"
                    onClick={() => navigate("/packages-child/1")}
                  >
                    ADD NEW PACKAGE
                  </button>
                  <button className="plusicon">
                    <i className="fa-regular fa-plus" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default PackagesHeader;
