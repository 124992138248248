import { useState, useEffect } from "react";
import "./cities.style.scss";
import { NavLink, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import del from "../../Assets/SideBar-Image/del.png";
import CitiesHeader from "../../Component/Header/CitiesHeader";
import { ConfirmDelete } from "../../Utills/ConfirmAlerts";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import NoData from "../../Utills/NoData/NoData";
import Loader from "../../Utills/Loader/Loader";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function Cities() {
  const axiosInstance = useApiService();
  const [loading, setLoading] = useState(true);
  const [heading, setHeading] = useState([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState(0);
  const pageSize = 10;

  const getCitiesDetails = () => {
    setLoading(true);
    axiosInstance
      .get(`admin/cities`, {
        params: { currentPage: page, pageSize: pageSize, search },
      })
      .then((response) => {
        setLoading(false);
        setTotal(response?.data?.totalCities);
        setHeading(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const deleteCityById = (_id: any) => {
    setLoading(true);
    axiosInstance
      .delete(`admin/city`, {
        params: { _id },
      })
      .then(() => {
        setLoading(false);
        ToastSuccess("Successfully Deleted.");
        navigate("/city", { replace: true });
        getCitiesDetails();
      })
      .catch((err) => {
        setLoading(false);
        ToastFailure(err.message);
      });
  };

  const onNavigate = (name: any, timezone: any, updatedAt: any, _id: any) => {
    navigate("/citiesChild", { state: { name, timezone, updatedAt, _id } });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getCitiesDetails();
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [search, page]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  return (
    <>
      <CitiesHeader setSearch={setSearch} />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <div className="Cities-container TopCommon MainClass">
          <div className="Cities">
            {heading?.length > 0 ? (
              heading.map((item: any, index) => {
                const { name, timezone, updatedAt } = item;
                return (
                  <div className="Cities-Box" key={index}>
                    <ul className="content-item">
                      <li
                        className="CitiesSec"
                        onClick={() =>
                          onNavigate(name, timezone, updatedAt, item._id)
                        }
                      >
                        <div className="content-Name">
                          <span className="moveicon" />
                          <div className="heading-content-title">
                            <span className="Cities-title">
                              <NavLink
                                to={`/citiesChild`}
                                className="include-parking"
                              >
                                <div className="Cities-contents">
                                  <div className="title">
                                    <span className="cities-name">
                                      {item.name.toUpperCase()}
                                    </span>
                                    <span className="place">{timezone}</span>
                                  </div>
                                </div>
                              </NavLink>
                            </span>
                          </div>
                        </div>
                        <button
                          className="delete-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            ConfirmDelete(item._id, deleteCityById);
                          }}
                        >
                          <a className="Delete-Cities">
                            <img
                              alt=""
                              src={del}
                              className="fa-solid fa-trash-can"
                            />
                            <span className="delete-team-member">DELETE</span>
                          </a>
                        </button>
                      </li>
                    </ul>
                  </div>
                );
              })
            ) : (
              <NoData content="No city found." />
            )}
            {total > pageSize && (
              <Pagination
                activePage={page}
                itemsCountPerPage={pageSize}
                totalItemsCount={total}
                onChange={(page) => setPage(page)}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Cities;
