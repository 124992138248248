import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ArchivHeader from "../../../../Component/Header/ChildHeader/ArchivHeader";
import {
  ConfirmDelete,
  GenericConfirm,
} from "../../../../Utills/ConfirmAlerts";
import NoData from "../../../../Utills/NoData/NoData";
import { ToastSuccess } from "../../../../Share/toast/ToastMsg";
import useApiService from "../../../../Utills/ApiAxiosHandler/ApiAxiosHandler";

// admin/get/archivedList
export default function ArchivedList() {
  const axiosInstance = useApiService();
  const [archivedData, setArchivedData] = useState([]);
  const { id } = useParams();

  // all Archived Data
  const getArchivedData = () => {
    axiosInstance
      .get(`admin/get/archivedList`, {
        params: {
          jobId: id,
        },
      })
      .then((response) => {
        setArchivedData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Restore Data
  const restoreArchive = (id: any) => {
    axiosInstance
      .put(`admin/restoreJobApplications?_id=${id}`, null)
      .then((response) => {
        ToastSuccess(response.data.message);
        getArchivedData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Data
  const deleteArchiv = (id: any) => {
    axiosInstance
      .delete(`admin/deleteJobApplications?_id=${id}`)
      .then((response) => {
        ToastSuccess(response.data.message);
        getArchivedData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getArchivedData();
  }, []);

  return (
    <>
      <ArchivHeader />
      <div className="FaqChild_container MainClass">
        <div className="faq-child TopCommonChild">
          {archivedData?.length > 0 && archivedData ? (
            archivedData.map((item: any) => {
              return (
                <div className="AccordinDiv archivedList" key={item._id}>
                  <Accordion
                    defaultActiveKey="0"
                    flush
                    className="archivedName"
                  >
                    <Accordion.Item
                      className="Accordian-Faq-field"
                      eventKey={item._id}
                    >
                      <Accordion.Header className="HeadingPath">
                        <h2
                          className="accordion-header-FaqChilds "
                          id="headingTwo"
                        >
                          <img alt="" className="downArrow" />
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            {item.name}
                          </button>
                        </h2>
                      </Accordion.Header>
                    </Accordion.Item>
                  </Accordion>
                  <div className="archived_Button">
                    <button
                      className="ArchiveBtn ArcBtn"
                      onClick={() => {
                        GenericConfirm({
                          key: item._id,
                          saveCallback: restoreArchive,
                          triggerMsg: `Are you sure, you want to restore data ?`,
                          SaveMsg: "Yes",
                        });
                      }}
                    >
                      Restore
                    </button>
                    <button
                      className="ArchiveBtn ArcBtn"
                      onClick={() => ConfirmDelete(item._id, deleteArchiv)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <center>
              <NoData content="No archive found" />
            </center>
          )}
        </div>
      </div>
    </>
  );
}
