import { useState, useEffect } from "react";
import "./Tickets.style.scss";
import { Accordion } from "react-bootstrap";
import downArrow from "../../Assets/SideBar-Image/drop-down-black.png";

import del from "../../Assets/SideBar-Image/del.png";
import TicketsHeader from "../../Component/Header/TicketsHeader";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import { ConfirmDelete } from "../../Utills/ConfirmAlerts";
import NoData from "../../Utills/NoData/NoData";
import Loader from "../../Utills/Loader/Loader";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";
import RichTag from "../../Utills/RichTag/RichTage";
import { isRichTextContextEmpty } from "../../Utills/utils";

function Tickets() {
  const axiosInstance = useApiService();
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);

  const getTicketsDetails = () => {
    setLoading(true);
    axiosInstance
      .get(`admin/ticket-submenu`)
      .then((response) => {
        setTickets(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const deleteTickets = async (_id: any) => {
    setLoading(true);
    await axiosInstance
      .delete(`admin/ticket-submenu`, {
        params: { _id },
      })
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          ToastSuccess("Successfully Deleted.");
          getTicketsDetails();
        } else {
          ToastFailure(response?.data?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const updateTicket = async (item: any) => {
    if (item.title.length == 0) {
      ToastFailure("Please enter title ");
      return;
    }
    if (isRichTextContextEmpty(item.content)) {
      ToastFailure("Please enter content");
      return;
    }
    setLoading(true);
    await axiosInstance
      .put(`admin/ticket-submenu`, item)
      .then((response) => {
        setLoading(false);
        getTicketsDetails();
        ToastSuccess(response.data.message);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getTicketsDetails();
  }, []);
  return (
    <>
      <TicketsHeader getTicketListHandler={getTicketsDetails} />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <div className="tickets-container MainClass">
          <div className="tickets TopCommon">
            <Accordion defaultActiveKey="0" flush>
              {tickets?.length > 0 ? (
                tickets.map((item: any, index: number) => {
                  return (
                    <Accordion.Item
                      className="Accordian-Faq-field"
                      key={item._id}
                      eventKey={item._id}
                    >
                      <Accordion.Header className="HeadingPath">
                        <div
                          className="accordion-header-FaqChilds "
                          id="headingTwo"
                        >
                          <div>
                            <img src={downArrow} alt="" className="downArrow" />
                          </div>
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            {item.title}
                          </button>
                          <div className="del-buttons ">
                            <button className="delete-btn deletebg">
                              <a
                                className="Delete-faq DeletedTickets"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  ConfirmDelete(item._id, deleteTickets);
                                }}
                              >
                                <img
                                  src={del}
                                  className="fa-solid fa-trash-can"
                                />
                                <span className="delete-team-member">
                                  DELETE
                                </span>
                              </a>
                            </button>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="accordion-body-FaqChild">
                          <div className="questions">
                            <div className="heading">
                              <span className="title-hed">Title</span>
                              <div className="btn-update">
                                <button
                                  className="Update-btn"
                                  onClick={() => updateTicket(item)}
                                >
                                  UPDATE
                                </button>
                              </div>
                            </div>
                            <div className="input-text">
                              <input
                                type="text"
                                name="text"
                                className="fields-text"
                                placeholder="2023 Season Seats"
                                value={item.title}
                                onChange={(e) => {
                                  const array: any = [...tickets];
                                  array[index].title = e.target.value;
                                  setTickets(array);
                                }}
                              />
                            </div>
                          </div>
                          <div className="TextEditorSec">
                            <p className="ContentHeading">Content</p>
                            <RichTag
                              editerDetails={item.content}
                              setEditerDetails={(value: any) => {
                                const array: any = [...tickets];
                                array[index].content = value;
                                setTickets(array);
                              }}
                            />
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })
              ) : (
                <NoData content="No ticket found." />
              )}
            </Accordion>
          </div>
        </div>
      )}
    </>
  );
}

export default Tickets;
