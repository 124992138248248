import { useState } from "react";
import logo from "../../Assets/SideBar-Image/logo.png";
import "../Login/Login.style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

export default function ResetPassword() {
  const axiosInstance = useApiService();
  const [formValues, setFormValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const myParam = useLocation();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (formValues.password !== formValues.confirmPassword) {
      ToastFailure("Password do not match");
      return;
    }
    axiosInstance
      .post(`password_reset`, {
        password: `${formValues.password}`,
        confirmPassword: `${formValues.confirmPassword}`,
        token: myParam.search.slice(7),
      })
      .then((response) => {
        if (response.status <= 0) {
          ToastFailure(response.data.message);
        } else {
          navigate("/login");
          ToastSuccess(response.data.message);
        }
      })
      .catch((error) => {
        ToastFailure(error.response.data.message);
        navigate("/login");
      });
  };

  return (
    <div className="loginform">
      <div className="bgimage">
        <div className="logo">
          <img className="waterfrontlogo" src={logo} alt="/" />

          <div className="logiform">
            <form className="formsubmistion" onSubmit={handleSubmit}>
              <div className="LoginSection">
                <div className="input-type-password">
                  <input
                    type="password"
                    autoComplete="off"
                    placeholder="NEW PASSWORD"
                    value={formValues.password}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        password: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-type-password">
                  <input
                    type="password"
                    autoComplete="off"
                    placeholder="CONFIRM PASSWORD"
                    value={formValues.confirmPassword}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        confirmPassword: e.target.value,
                      })
                    }
                  />
                </div>
                <button type="submit" className="btn-primary">
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
