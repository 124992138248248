import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastFailure, ToastSuccess } from "../../../Share/toast/ToastMsg";
import "../Category.scss";
import useApiService from "../../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function CategoryModel(props: any) {
  const axiosInstance = useApiService();
  const [categoryName, setCategoryName] = useState(
    props.data ? props.data.categoryName : ""
  );

  const onChangeHandler = (event: any) => {
    const { value } = event.target;
    setCategoryName(value);
  };

  const handleSubmit = (type: any) => {
    if (!categoryName.trim()) {
      ToastFailure("Please enter Category name..!");
      return;
    }

    if (type == "add") {
      handleCategoryAdd();
    } else {
      handleUpdate(props.data._id);
    }
  };

  // Add Category
  const handleCategoryAdd = async () => {
    try {
      const response = await axiosInstance.post(`admin/jobCategory`, {
        categoryName,
      });
      if (response.status === 200 && response.data.status === 1) {
        ToastSuccess(response.data.message);
        handleClose();
        props.getCategoryList();
      } else {
        ToastFailure(response.data?.error);
      }
    } catch (error: any) {
      ToastFailure(error.message);
    }
  };

  // Update Category
  const handleUpdate = async (id: any) => {
    try {
      const response = await axiosInstance.put(`admin/jobCategory`, {
        _id: id,
        categoryName,
      });
      if (response.status === 200 && response.data.status === 1) {
        ToastSuccess(response.data.message);
        handleClose();
        props.getCategoryList();
      } else {
        ToastFailure(response.data?.error);
      }
    } catch (error: any) {
      ToastFailure(error.message);
    }
  };

  const handleClose = () => {
    setCategoryName("");
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.type == "add" ? "Add Category" : "Update Category"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="CategoryChildSec">
            <div className="CategorySec">
              <div className="CategoryChild">
                <div className="name">
                  <label htmlFor="/" className="Name">
                    NAME
                  </label>
                  <input
                    type="text"
                    name="Name"
                    value={categoryName}
                    placeholder="Name"
                    className="CategoryName"
                    onChange={onChangeHandler}
                    maxLength={30}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} className="done-btn btnClose">
          Cancel
        </Button>
        <Button
          onClick={() => handleSubmit(props.type)}
          className="done-btn btnUpdate"
        >
          {props.type == "add" ? "Add" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CategoryModel;
