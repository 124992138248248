import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import backimage from "../../../Assets/SideBar-Image/ic-header-back.png";
interface Iprops {
  headerTitle: any;
  updatedAt: any;
  updatedBy: any;
}
const ChildPackagesHeader: React.FC<Iprops> = ({
  headerTitle,
  updatedAt,
  updatedBy,
}) => {
  return (
    <div className="header">
      <nav className="navbar-child">
        <div className="content-Child-Headers AddCityHeader">
          <div className="headding-child-Headers">
            <div className="backimage">
              <Link to="/package">
                <img src={backimage} alt="" className="back-arrow" />
              </Link>
            </div>
            <div className="PackageChildHeader">
              <a className="navbarBrand-childeheaders">{headerTitle}</a>
              <p className="lastEditeby">
                Last edited:{moment(updatedAt).format("Do MMM YY LT")} by{" "}
                {updatedBy}
              </p>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default ChildPackagesHeader;
