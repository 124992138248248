import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";
// import del from "../../../Assets/SideBar-Image/del.png";
import downArrow from "../../../Assets/SideBar-Image/drop-down-black.png";
import ApplicentHeader from "../../../Component/Header/ChildHeader/ApplicentHeader";
import Loader from "../../../Utills/Loader/Loader";
import "./Applicent.scss";
import NoData from "../../../Utills/NoData/NoData";
import { ConfirmChange } from "../../../Utills/ConfirmAlerts";
import { ToastSuccess } from "../../../Share/toast/ToastMsg";
import useApiService from "../../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function Applicent() {
  const axiosInstance = useApiService();
  const [loading, setLoading] = useState(false);
  const [applicentDetails, setApplicentDetails] = useState([]);
  const { id } = useParams();
  const cdnUrl = process.env.REACT_APP_CDN_URl;

  const getJobsBox = () => {
    setLoading(true);
    axiosInstance
      .get(`admin/apply-jobs?_id=${id}`)
      .then((response) => {
        setLoading(false);
        setApplicentDetails(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  // add to ARCHIVE
  const addToArchive = (id: any) => {
    setLoading(true);
    axiosInstance
      .put(`admin/archiveJobApplications?_id=${id}`)
      .then((response) => {
        setLoading(false);
        ToastSuccess(response.data.message);
        getJobsBox();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getJobsBox();
  }, []);
  return (
    <>
      <ApplicentHeader id={id} />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <div className="FaqChild_container MainClass">
          <div className="faq-child TopCommonChild">
            {applicentDetails?.length > 0 ? (
              applicentDetails.map((item: any) => {
                return (
                  <div className="AccordinDiv" key={item._id}>
                    <Accordion defaultActiveKey="0" flush>
                      <>
                        <Accordion.Item
                          className="Accordian-Faq-field"
                          eventKey={item._id}
                        >
                          <Accordion.Header className="HeadingPath">
                            <h2
                              className="accordion-header-FaqChilds "
                              id="headingTwo"
                            >
                              <img
                                src={downArrow}
                                alt=""
                                className="downArrow"
                              />
                              <button
                                className="accordion-button "
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                {item.name}
                              </button>
                            </h2>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="accordion-body-FaqChild FaqModalTop">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="questions">
                                    <div className="questions-faq">
                                      <div className="ApplicentName">
                                        <label
                                          htmlFor=""
                                          className="Applicent-Email-title CommonLabel"
                                        >
                                          Email
                                        </label>
                                        <p className="question-inputsbox">
                                          {item.email}
                                        </p>
                                      </div>
                                      <div className="ApplicentName">
                                        <label
                                          htmlFor=""
                                          className="Applicent-Email-title CommonLabel"
                                        >
                                          Are you over the age of 18?
                                        </label>
                                        <p className="question-inputsbox">
                                          {item.isEighteen}
                                        </p>
                                      </div>
                                      <div className="ApplicentName">
                                        <label
                                          htmlFor=""
                                          className="Applicent-Email-title CommonLabel"
                                        >
                                          Cover Letter
                                        </label>
                                        <p className="question-inputsbox">
                                          {item.coverLatter}
                                        </p>
                                      </div>
                                      <div className="ApplicentName">
                                        <label
                                          htmlFor=""
                                          className="Applicent-Email-title CommonLabel"
                                        >
                                          Have you worked with PSM before?
                                        </label>
                                        <p className="question-inputsbox">
                                          {item.isWorkedWithPsm}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="ApplicentName">
                                    <label
                                      htmlFor=""
                                      className="Applicent-Email-title CommonLabel"
                                    >
                                      Mailing Address
                                    </label>
                                    <p className="question-inputsbox">
                                      {item.mailingAddress}
                                    </p>
                                  </div>

                                  <div className="ApplicentName">
                                    <label
                                      htmlFor=""
                                      className="Applicent-Email-title CommonLabel"
                                    >
                                      Phone Number
                                    </label>
                                    <p className="question-inputsbox">
                                      {item.phoneNumber}
                                    </p>
                                  </div>

                                  <div className="ApplicentName">
                                    <label
                                      htmlFor=""
                                      className="Applicent-Email-title CommonLabel"
                                    >
                                      Customer Service Experience
                                    </label>
                                    <p className="question-inputsbox">
                                      {item.customerServiceExperience}
                                    </p>
                                  </div>

                                  <div className="ApplicentName">
                                    <label
                                      htmlFor=""
                                      className="Applicent-Email-title CommonLabel"
                                    >
                                      How did you hear about us?
                                    </label>
                                    <p className="question-inputsbox">
                                      {item.hearAboutUs}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  {item?.ApplicantsAnswers
                                    ? item.ApplicantsAnswers.map(
                                        (ques: any, index: any) => {
                                          return (
                                            <div
                                              className="QuestionAnswerList"
                                              key={index}
                                            >
                                              <div className="QuestionList">
                                                Q{index + 1}.
                                                {ques.question
                                                  ? ques.question
                                                  : "Question not available."}
                                              </div>
                                              <div className="AnswerList">
                                                <b>Ans.</b>
                                                {ques?.answer
                                                  ? ques.answer
                                                  : "Answers not available."}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                    : ""}
                                </div>
                                {item.attachment && (
                                  <div className="col-md-12">
                                    <div className="DownloadResume">
                                      <a
                                        href={`${cdnUrl}${item.attachment}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Download Resume
                                      </a>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </>
                    </Accordion>
                    <div>
                      <button
                        className="ArchiveBtn"
                        onClick={() =>
                          ConfirmChange(
                            "Are you sure ?",
                            item._id,
                            addToArchive
                          )
                        }
                      >
                        Archive
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <NoData content="Applicant not found" />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Applicent;
