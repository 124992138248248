import React from "react";
import { Link } from "react-router-dom";
import backimage from "../../../Assets/SideBar-Image/ic-header-back.png";
import Footer from "../../Footer/Footer";
interface Iprops {
  title: any;
  isPublished: any;
  setChildJobsDetails: any;
}
const JobsChildHeader: React.FC<Iprops> = ({
  title,
  isPublished,
  setChildJobsDetails,
}) => {
  return (
    <>
      <div className="header EventChildHeader">
        <nav className="navbar-child">
          <div className="content-Child-Headers">
            <div className="headding-child-Headers">
              <div className="child-Header-content">
                <div className="backimage">
                  <Link to="/jobs">
                    <img src={backimage} alt="" className="back-arrow" />
                  </Link>
                </div>
                <a className="navbarBrand-childeheaders">{title || ""}</a>
              </div>
              <div className="dropdown-childHeader">
                <div className="draft-public">
                  <select
                    name="selector-childHeader"
                    className="selected-items"
                    onChange={(e) => {
                      e.target.value === "DRAFT"
                        ? setChildJobsDetails((oldState: any) => {
                            return { ...oldState, isPublished: false };
                          })
                        : setChildJobsDetails((oldState: any) => {
                            return { ...oldState, isPublished: true };
                          });
                    }}
                  >
                    <option value="DRAFT">DRAFT</option>
                    <option value="PUBLISHED" selected={isPublished}>
                      PUBLISHED
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <Footer isJobs />
    </>
  );
};

export default JobsChildHeader;
