import { useState, useEffect } from "react";
import "./Venues.style.scss";
import { useNavigate } from "react-router-dom";
import featured from "../../Assets/SideBar-Image/ic-featured.png";
import VenuesHeader from "../../Component/Header/VenuesHeader";
import { ToastSuccess } from "../../Share/toast/ToastMsg";
import NoData from "../../Utills/NoData/NoData";
import Loader from "../../Utills/Loader/Loader";
import Pagination from "react-js-pagination";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function Venues() {
  const axiosInstance = useApiService();
  const [consert, setconcert] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const pageSize = 10;
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const getConcertDetails = () => {
    setLoading(true);
    axiosInstance
      .get(`admin/venues`, {
        params: { currentPage: page, pageSize, search },
      })
      .then((response) => {
        setLoading(false);
        setconcert(response.data.data);
        setTotal(response?.data?.totalVenues ?? 0);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getConcertDetails();
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [search, page]);

  const isFeartureActive = async (e: any, _id: any, isFeatured: any) => {
    e.stopPropagation();
    setLoading(true);
    await axiosInstance
      .put(`admin/venue/status`, {
        _id,
        isFeatured: !isFeatured,
      })
      .then((response) => {
        setLoading(false);
        ToastSuccess(response.data.message);
        getConcertDetails();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const searchHandler = (data: any) => {
    setSearch(data);
    setPage(1);
  };

  return (
    <>
      <VenuesHeader setSearch={searchHandler} />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <div className="venues-main TopCommon MainClass">
          <div className="container">
            <div className="row venueSec">
              {consert?.length > 0 ? (
                consert.map((item: any, index) => {
                  return (
                    <div className="col-md-4" key={index}>
                      <div className="venuse-box" draggable="true">
                        <div
                          className="card"
                          onClick={() => navigate(`/venuesChild/${item._id}`)}
                        >
                          <img
                            src={item.featureImage}
                            className="card-img-top"
                            alt="..."
                          />
                          <div className="card-body">
                            <h5 className="card-title">{item.title}</h5>
                            <p className="card-text">
                              {item.address},{item.city}
                            </p>
                          </div>
                        </div>
                        <div className="featurebtn">
                          <div
                            className="fearture-btn"
                            aria-disabled={item.isFeatured}
                          >
                            <div
                              className={`${
                                item.isFeatured
                                  ? `events btn-btn feature-pointer`
                                  : `btn-cnt-active`
                              } `}
                              onClick={(e) =>
                                isFeartureActive(e, item._id, item.isFeatured)
                              }
                            >
                              <span className="package">
                                <img src={featured} />
                                <span className="button-cnt">FEATURED</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoData content="No venues created yet." />
              )}
              {total > pageSize && (
                <Pagination
                  activePage={page}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={total}
                  onChange={(page: any) => {
                    setPage(page);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Venues;
