import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./UploadEventImage.style.scss";
import { useState } from "react";
import ImageCropper from "../../../Component/PopWIndow/imageCropper";
import getCroppedImg from "../../../Component/PopWIndow/cropImage";
import { ToastFailure, ToastSuccess } from "../../../Share/toast/ToastMsg";
import useApiService from "../../../Utills/ApiAxiosHandler/ApiAxiosHandler";
import { ClipLoader } from "react-spinners";

function UploadEventImage(props: any) {
  const axiosInstance = useApiService();

  const {
    setCroppedBannerCloud,
    setCroppedFeature,
    galleryImages,
    setGalleryImages,
    bannerImage,
    featureImage,
  } = props;

  const [showImage, setShowImage] = useState<any>({
    bytes: "",
    file: bannerImage,
  });

  const [smallShowImage, setSmallShowImage] = useState<any>({
    bytes: "",
    file: featureImage,
  });

  const [loading, setLoading] = useState(false);

  const [bannerCropImage, setBannerCropImage] = useState<any>();
  const [featureCropImage, setFeatureCropImage] = useState<any>();

  const handleImagesShow = (event: any) => {
    setShowImage({
      bytes: event.target.files[0],
      file: URL.createObjectURL(event.target.files[0]),
    });
  };

  const handleImagesShowSmall = (event: any) => {
    setSmallShowImage({
      bytes: event.target.files[0],
      file: URL.createObjectURL(event.target.files[0]),
    });
  };

  const handleNewGallery = (event: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
      setLoading(true);
      axiosInstance
        .post(`s3/upload-file`, {
          contentType: event.target.files[0].type,
          image: reader.result,
        })
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            galleryImages.push(response?.data?.data?.imageUrl);
            setGalleryImages(galleryImages);
            ToastSuccess("Image uploaded successfully.");
          } else {
            ToastFailure(response?.data?.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    };
    reader.onerror = function (error) {
      ToastFailure("Error in uploading event image");
      console.log("Error in event upload image : ", error);
    };
  };

  const cropBannerImage = async () => {
    if (showImage?.bytes) {
      if (!showImage?.bytes?.type?.includes("image")) {
        ToastFailure("Please upload valid image file");
        return;
      }
      setLoading(true);
      const croppedImage = await getCroppedImg(showImage.file, bannerCropImage);
      axiosInstance
        .post(`s3/upload-file`, {
          contentType: showImage.bytes.type,
          image: croppedImage,
        })
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            setCroppedBannerCloud(response?.data?.data?.imageUrl);
            ToastSuccess("Image uploaded successfully.");
          } else {
            ToastFailure(response?.data?.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          ToastFailure("Error in uploading image");
          console.log(error);
        });
    } else {
      ToastFailure(
        "Please choose the image. If its already selected, undo/delete the selection, and then choose the image again"
      );
    }
  };

  const cropFeatureImage = async () => {
    if (smallShowImage.bytes) {
      if (!smallShowImage?.bytes?.type?.includes("image")) {
        ToastFailure("Please upload valid image file");
        return;
      }
      setLoading(true);
      const croppedImage = await getCroppedImg(
        smallShowImage.file,
        featureCropImage
      );

      axiosInstance
        .post(`s3/upload-file`, {
          contentType: smallShowImage.bytes.type,
          image: croppedImage,
        })
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            setCroppedFeature(response?.data?.data?.imageUrl);
            ToastSuccess("Image uploaded successfully.");
          } else {
            ToastFailure(response?.data?.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          ToastFailure("Error in uploading image");
          console.log(error);
        });
    } else {
      ToastFailure(
        "Please choose the image. If its already selected, undo/delete the selection, and then choose the image again"
      );
    }
  };

  // delete gallery image
  const removeGalleryImages = (url: any) => {
    const sendDeleteImageUrl = new URL(url);
    let deleteImageUrl;
    if (sendDeleteImageUrl.pathname[0] === "/") {
      deleteImageUrl = sendDeleteImageUrl.pathname.substring(1);
    } else {
      deleteImageUrl = sendDeleteImageUrl.pathname;
    }
    axiosInstance
      .delete(`s3/remove-file`, {
        data: {
          image: deleteImageUrl,
        },
      })
      .then(() => {
        const filterGalleryImage = galleryImages.filter(
          (list: any) => list !== url
        );
        setGalleryImages(filterGalleryImage);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="imageLoderSec">
            EVENT IMAGES {loading && <ClipLoader color="#ff2851" size={25} />}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="image-crope-container">
          <div className="crop-image-upload">
            <div className="image-size-title">
              <p className="images-size">EVENT BANNER (335 * 155)</p>
              <div className="import-image-section">
                {showImage.file ? (
                  <ImageCropper
                    image={showImage.file}
                    aspect={335 / 155}
                    croppedArea={(data: any) => setBannerCropImage(data)}
                    containerStyle={{
                      width: "29%",
                      height: "216px",
                      top: "23px",
                      left: "95px",
                      margin: "30px",
                      backgroundColor: "#fff",
                      overflow: "hidden",
                    }}
                  />
                ) : (
                  <div className="import-crop-image">
                    <div className="iamges-selector">
                      <input
                        type="file"
                        name="uploadImage"
                        className="selecting-images-upload"
                        accept="image/*"
                        onChange={(event) => handleImagesShow(event)}
                      />
                      <label htmlFor="/" className="Drage-&-drop">
                        Click to import or Drag & Drop
                      </label>
                    </div>
                  </div>
                )}
              </div>

              <div className="button-Section">
                <div className="buttons-undo-redo-crop">
                  <div className="undo-button">
                    <button
                      type="button"
                      className="undo"
                      onClick={() => {
                        setShowImage([]);
                      }}
                    >
                      UNDO
                    </button>
                  </div>
                  <div className="undo-button">
                    <button
                      type="button"
                      className="undo"
                      onClick={() => {
                        setShowImage([]);
                      }}
                    >
                      DELETE
                    </button>
                  </div>
                  <div className="undo-button">
                    <button
                      type="button"
                      className="undo"
                      onClick={() => cropBannerImage()}
                      disabled={loading}
                    >
                      CROP & UPLOAD
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="image-crop-size-title Testing">
              <p className="images-size">
                EVENT FEATURED IMAGE (270 * 270) testing
              </p>
              <div className="import-image-right-section">
                {smallShowImage.file ? (
                  <ImageCropper
                    image={smallShowImage.file}
                    aspect={270 / 270}
                    croppedArea={(data: any) => setFeatureCropImage(data)}
                    containerStyle={{
                      width: "29%",
                      height: "216px",
                      top: "24px",
                      left: "659px",
                      margin: "30px",
                      backgroundColor: "#fff",
                      overflow: "hidden",
                    }}
                  />
                ) : (
                  <div className="import-crop-right-image">
                    <div className="iamges-right-selector">
                      <input
                        type="file"
                        name="uploadImage"
                        className="selecting-images-upload"
                        accept="image/*"
                        onChange={(event) => handleImagesShowSmall(event)}
                      />
                      <label htmlFor="/" className="Drage-drop">
                        Click to import or Drag & Drop
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div className="button-Section">
                <div className="buttons-undo-content">
                  <div className="undo-one">
                    <div className="undo-button-small-content">
                      <button
                        type="button"
                        className="undo-small-content"
                        onClick={() => {
                          setSmallShowImage([]);
                        }}
                      >
                        UNDO
                      </button>
                    </div>
                    <div className="undo-button-small-content">
                      <button
                        type="button"
                        className="undo-small-content"
                        onClick={() => {
                          setSmallShowImage([]);
                        }}
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                  <div className="undo-button-small-content">
                    <button
                      type="button"
                      className="undo-small"
                      onClick={() => cropFeatureImage()}
                      disabled={loading}
                    >
                      CROP & UPLOAD
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="gallaryselection">
            <div className="selecte-photos">
              {galleryImages?.length > 0 &&
                galleryImages.map((item: any, index: number) => {
                  return (
                    <div className="GalleryImgDiv" key={index}>
                      <img src={item} className="smallUploadImage" />
                      <span onClick={() => removeGalleryImages(item)}>
                        <i
                          className="fa-sharp fa-solid fa-xmark"
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <span className="gallery-btn ">
          <input
            type="file"
            className="addgallary"
            accept="image/*"
            onChange={(event) => handleNewGallery(event)}
          />
          <label htmlFor="file" className="type-file addToGallary">
            Add to gallery
          </label>
        </span>

        <Button onClick={props.onHide} className="done-btn">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UploadEventImage;
