import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./UploadEventImage.style.scss";
import { useState } from "react";
import { ToastFailure, ToastSuccess } from "../../../Share/toast/ToastMsg";
import getCroppedImg from "../../../Component/PopWIndow/cropImage";
import ImageCropper from "../../../Component/PopWIndow/imageCropper";
import useApiService from "../../../Utills/ApiAxiosHandler/ApiAxiosHandler";
import { ClipLoader } from "react-spinners";

interface Props {
  setCroppedBannerCloud: any;
  setCroppedFeature: any;
  awsChartUrlCloud: any;
  setAwsChartUrlCloud: any;
  awsGallaryUrlCloud: any;
  setAwsGallaryUrlCloud: any;
  deleteItem: any;
  bannerImage: any;
  featureImage: any;
  show?: any;
  onHide?: any;
}

const UploadEventImage: React.FC<Props> = ({
  setCroppedBannerCloud,
  setCroppedFeature,
  awsChartUrlCloud,
  setAwsChartUrlCloud,
  awsGallaryUrlCloud,
  setAwsGallaryUrlCloud,
  deleteItem,
  show,
  onHide,
  bannerImage,
  featureImage,
}) => {
  const axiosInstance = useApiService();
  const [showImage, setShowImage] = useState<any>({
    bytes: "",
    file: bannerImage,
  });
  const [smallShowImage, setSmallShowImage] = useState<any>({
    bytes: "",
    file: featureImage,
  });

  const [loading, setLoading] = useState(false);

  const [bannerCropImage, setBannerCropImage] = useState<any>();
  const [featureCropImage, setFeatureCropImage] = useState<any>();

  const handleImagesShow = (event: any) => {
    setShowImage({
      bytes: event.target.files[0],
      file: URL.createObjectURL(event.target.files[0]),
    });
  };

  const handleImagesShowSmall = (event: any) => {
    setSmallShowImage({
      bytes: event.target.files[0],
      file: URL.createObjectURL(event.target.files[0]),
    });
  };

  function getBase64(file: any, flag: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (flag == 1) {
        addSeatingChartImg(reader.result);
      } else {
        addGallaryImg(reader.result);
      }
    };
    reader.onerror = function (error) {
      console.log(error);
    };
  }

  const handeChartUpload = async (event: any) => {
    getBase64(event.target.files[0], 1);
  };

  const addNewGallaryImage = (event: any) => {
    getBase64(event.target.files[0], 2);
  };

  const cropBannerImage = async () => {
    if (showImage?.bytes) {
      if (!showImage?.bytes?.type?.includes("image")) {
        ToastFailure("Please upload valid image file");
        return;
      }
      setLoading(true);
      const croppedImage = await getCroppedImg(showImage.file, bannerCropImage);
      axiosInstance
        .post(`s3/upload-file`, {
          contentType: showImage.bytes.type,
          image: croppedImage,
        })
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            setCroppedBannerCloud(response?.data?.data?.imageUrl);
            ToastSuccess("Image uploaded successfully.");
          } else {
            ToastFailure(response?.data?.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      ToastFailure(
        "Please choose the image. If its already selected, undo/delete the selection, and then choose the image again"
      );
    }
  };

  const cropFeatureImage = async () => {
    if (smallShowImage?.bytes) {
      if (!smallShowImage?.bytes?.type?.includes("image")) {
        ToastFailure("Please upload valid image file");
        return;
      }
      const croppedImage = await getCroppedImg(
        smallShowImage.file,
        featureCropImage
      );
      setLoading(true);
      axiosInstance
        .post(`s3/upload-file`, {
          contentType: smallShowImage.bytes.type,
          image: croppedImage,
        })
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            setCroppedFeature(response?.data?.data?.imageUrl);
            ToastSuccess("Image uploaded successfully.");
          } else {
            ToastFailure(response?.data?.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      ToastFailure(
        "Please choose the image. If its already selected, undo/delete the selection, and then choose the image again"
      );
    }
  };

  const addSeatingChartImg = async (base64: any) => {
    setLoading(true);
    axiosInstance
      .post(`s3/upload-file`, {
        contentType: "image/png",
        image: base64,
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          awsChartUrlCloud.push(response?.data?.data?.imageUrl);
          setAwsChartUrlCloud(awsChartUrlCloud);
          ToastSuccess("Image uploaded successfully.");
        } else {
          ToastFailure(response?.data?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const addGallaryImg = async (base64: any) => {
    setLoading(true);
    axiosInstance
      .post(`s3/upload-file`, {
        contentType: "image/png",
        image: base64,
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          awsGallaryUrlCloud.push(response?.data?.data?.imageUrl);
          setAwsGallaryUrlCloud(awsGallaryUrlCloud);
          ToastSuccess("Image uploaded successfully.");
        } else {
          ToastFailure(response?.data?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <Modal
      show={show}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHide()}
      className="VenueModalSection"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="imageLoderSec">
            VENUE IMAGES {loading && <ClipLoader color="#ff2851" size={25} />}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="image-crope-container">
          <div className="crop-image-upload">
            <div className="image-size-title venueCropper">
              <p className="images-size">VENUE BANNER (270 * 90)</p>
              <div className="import-image-section FirstSec SecondSec">
                {showImage.file ? (
                  <ImageCropper
                    image={showImage.file}
                    aspect={270 / 90}
                    croppedArea={(data: any) => setBannerCropImage(data)}
                    containerStyle={{
                      width: "29%",
                      height: "218px",
                      top: "23px",
                      left: "96px",
                      margin: "30px",
                      backgroundColor: "#fff",
                      overflow: "hidden",
                    }}
                  />
                ) : (
                  <div className="import-crop-image">
                    <div className="iamges-selector">
                      <input
                        type="file"
                        name="uploadImage"
                        className="selecting-images-upload"
                        accept="image/*"
                        onChange={(event) => handleImagesShow(event)}
                      />
                      <div className="Drage-&-drop">
                        Click to import or Drag & Drop
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="button-Section">
                <div className="buttons-undo-redo-crop">
                  <div className="undo-button">
                    <button className="undo" onClick={() => setShowImage([])}>
                      UNDO
                    </button>
                  </div>
                  <div className="undo-button">
                    <button className="undo" onClick={() => setShowImage([])}>
                      DELETE
                    </button>
                  </div>
                  <div className="undo-button">
                    <button
                      className="undo"
                      onClick={() => cropBannerImage()}
                      disabled={loading}
                    >
                      CROP & UPLOAD
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="image-crop-size-title venueCropper2">
              <p className="images-size">VENUE FEATURED IMAGE (190 * 90)</p>
              <div className="import-image-right-section">
                {smallShowImage.file ? (
                  <ImageCropper
                    image={smallShowImage.file}
                    aspect={190 / 90}
                    croppedArea={(data: any) => setFeatureCropImage(data)}
                    containerStyle={{
                      width: "29%",
                      height: "217px",
                      top: "24px",
                      left: "658px",
                      margin: "30px",
                      backgroundColor: "#fff",
                      overflow: "hidden",
                    }}
                  />
                ) : (
                  <div className="import-crop-right-image">
                    <div className="iamges-right-selector">
                      <input
                        type="file"
                        name="uploadImage"
                        className="selecting-images-upload"
                        accept="image/*"
                        onChange={(event) => handleImagesShowSmall(event)}
                      />
                      <div className="Drage-drop">
                        Click to import or Drag & Drop
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="button-Section">
                <div className="buttons-undo-content">
                  <div className="undo-one">
                    <div className="undo-button-small-content">
                      <button
                        className="undo-small-content"
                        onClick={() => setSmallShowImage([])}
                      >
                        UNDO
                      </button>
                    </div>
                    <div className="undo-button-small-content">
                      <button
                        className="undo-small-content"
                        onClick={() => setSmallShowImage([])}
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                  <div className="undo-button-small-content">
                    <button
                      className="undo-small"
                      onClick={() => cropFeatureImage()}
                      disabled={loading}
                    >
                      CROP & UPLOAD
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="gallaryselection chart-selection">
          <h6 className="galleryHeading">SEATING CHART IMAGES </h6>
          <div className="selecte-photos">
            {awsChartUrlCloud ? (
              awsChartUrlCloud.map((item: any, index: number) => {
                return (
                  <div className="modalImageDiv" key={index}>
                    <img src={item} className="smallUploadImg" />
                    <span
                      onClick={() => deleteItem(item, "venue_event_image", 1)}
                    >
                      <i
                        className="fa-solid fa-xmark"
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
        <hr />
        <div className="gallaryselection">
          <h6 className="galleryHeading">GALLERY SECTION </h6>
          <div className="selecte-photos">
            {awsGallaryUrlCloud ? (
              awsGallaryUrlCloud.map((item: any, index: number) => {
                return (
                  <div className="modalImageDiv" key={index}>
                    <img src={item} className="smallUploadImg" />
                    <span
                      onClick={() => deleteItem(item, "venue_event_image", 2)}
                    >
                      <i
                        className="fa-solid fa-xmark"
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <span className="gallery-btn">
          <input
            type="file"
            className="addgallary"
            accept="image/*"
            onChange={(event) => addNewGallaryImage(event)}
          />
          <button className="type-file">ADD TO GALLERY</button>
        </span>
        <span className="gallery-btn">
          <input
            type="file"
            className="SecondGallery"
            accept="image/*"
            onChange={(event) => handeChartUpload(event)}
          />
          <button className="type-file">UPLOAD SEATING CHART</button>
        </span>

        <Button onClick={() => onHide()} className="done-btn">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadEventImage;
