import { ErrorMessage } from "formik";
import del from "../../Assets/SideBar-Image/del.png";
import radio from "../../Assets/SideBar-Image/ic-radio.png";
import spotify from "../../Assets/SideBar-Image/ic-spotif.png";
import attach from "../../Assets/SideBar-Image/ic-attach.png";
import "./AddMedia.style.scss";
import { GenericConfirm } from "../../Utills/ConfirmAlerts";
import NoData from "../../Utills/NoData/NoData";

function AddMedia(props: any) {
  const { values, setValues } = props;

  const handleRemoveClick = (index: any) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
  };

  const handleAddClick = () => {
    const list = [...values];
    list.unshift({
      title: "",
      link: "",
      type: "",
    });

    setValues(list);
  };

  return (
    <div>
      <div className="media-button">
        <div className="disclosiv">
          <button className="addpack" type="button" onClick={handleAddClick}>
            ADD MEDIA
          </button>
          <div className="hrline" />
        </div>
      </div>
      {values?.length > 0 ? (
        values.map((item: any, index: any) => {
          return (
            <div key={index} className="box">
              <div className="media-boxes">
                <div className="media-content">
                  <div className="media-icons-title">
                    <span className="iconic">ICON</span>
                  </div>
                  <div className="media-icons">
                    <span
                      className={`${
                        item.type === "Radio" && "icon_border_active"
                      } icon_border`}
                      onClick={() => {
                        const list = [...values];
                        list[index].type = "Radio";
                        setValues(list);
                      }}
                    >
                      <img src={radio} className="radio-icon" alt="/" />
                    </span>
                    <span
                      className={`${
                        item.type === "Spotify" && "icon_border_active"
                      } icon_border`}
                      onClick={() => {
                        const list = [...values];
                        list[index].type = "Spotify";
                        setValues(list);
                      }}
                    >
                      <img src={spotify} className="spotify-icon" alt="" />
                    </span>
                    <span
                      className={`${
                        item.type === "Attach" && "icon_border_active"
                      } icon_border`}
                      onClick={() => {
                        const list = [...values];
                        list[index].type = "Attach";
                        setValues(list);
                      }}
                    >
                      <img src={attach} className="attach-icon" alt="" />
                    </span>
                  </div>
                </div>
                <div className="typing-area">
                  <div className="links-title-media">
                    <div className="link-names-title">
                      <label className="CommonLabel">TITLE</label>
                      <input
                        value={item.title}
                        onChange={(e) => {
                          const list = [...values];
                          list[index].title = e.target.value;
                          setValues(list);
                        }}
                      />
                    </div>
                    <div className="link-names-title">
                      <label className="CommonLabel">MEDIA LINK</label>
                      <input
                        value={item.link}
                        onChange={(e) => {
                          const list = [...values];
                          list[index].link = e.target.value;
                          setValues(list);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="deletes-btns">
                  <button
                    type="button"
                    className="dele-btn"
                    onClick={() => {
                      GenericConfirm({
                        key: index,
                        saveCallback: handleRemoveClick,
                        triggerMsg:
                          "Are you sure, you want to delete this Media?",
                        SaveMsg: "Okay",
                      });
                    }}
                  >
                    <span className="Delete-Media">
                      <img src={del} alt="" className="fa-solid fa-trash-can" />
                      <span className="delete-medias-section">
                        DELETE SECTION
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="NoData_margin">
          <NoData content="No media is found." />
        </div>
      )}
    </div>
  );
}

export default AddMedia;
