import { useState } from "react";
import "./Header.style.scss";
import { SidebarHeading } from "../../Store/Store";
import PropTypes from "prop-types";

interface Iprops {
  setSearch?: any;
  viewSearch?: any;
}

const HomeBanners: React.FC<Iprops> = ({ setSearch, viewSearch }) => {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <div className="header">
        <nav className="navbar">
          <div className="content-Event">
            <div className="headding">
              <a className="navbarBrand">{SidebarHeading[6]}</a>
            </div>
            <div className="form homeBannerSearchSec">
              {viewSearch == 1 && (
                <div className="searchBar">
                  <input
                    className="search"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <a className="searchicone">
                    <i className="fa-solid fa-magnifying-glass" />
                  </a>
                  <div
                    className="searchicone SearchFeildMobile"
                    onClick={() => setVisible(!visible)}
                  >
                    {visible ? (
                      <div className="searchicone new">
                        <i className="fa-sharp fa-solid fa-xmark fa-magnifying-glass" />
                      </div>
                    ) : (
                      <div className="searchicone">
                        <i className="fa-solid fa-magnifying-glass" />
                      </div>
                    )}
                  </div>
                  {visible && (
                    <div className="SearchFieldMobile">
                      <span>
                        <i className="fa-solid fa-magnifying-glass" />
                      </span>
                      <input
                        type="text"
                        name="text"
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                        defaultValue=""
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

HomeBanners.propTypes = {
  setSearch: PropTypes.any.isRequired,
};

export default HomeBanners;
