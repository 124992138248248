import { useState } from "react";
import logo from "../../Assets/SideBar-Image/logo.png";
import "../Login/Login.style.scss";
import { useNavigate } from "react-router-dom";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import { isEmail } from "../../Utills/utils";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

export default function ForgotPassword() {
  const axiosInstance = useApiService();
  const [formValues, setFormValues] = useState({ email: "" });

  const navigate = useNavigate();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (formValues.email.length === 0) {
      ToastFailure("Please Enter the  email");
      return;
    }
    if (!isEmail(formValues.email)) {
      ToastFailure("Please Enter the valid email");
      return;
    }

    axiosInstance
      .post(`forget_password`, {
        email: `${formValues.email}`,
      })
      .then((response) => {
        if (response.data.status === 0) {
          ToastFailure(response.data.message);
        } else {
          ToastSuccess(response.data.message);
          navigate("/login");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="loginform">
      <div className="bgimage">
        <div className="logo">
          <img className="waterfrontlogo" src={logo} alt="/" />

          <div className="logiform">
            <form className="formsubmistion" onSubmit={handleSubmit} noValidate>
              <div className="LoginSection">
                <div className="input-type-email">
                  <input
                    type="email"
                    autoComplete="off"
                    placeholder="EMAIL"
                    value={formValues.email}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        email: e.target.value,
                      })
                    }
                  />
                </div>

                <button type="submit" className="btn-primary">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
