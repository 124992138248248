import "./Footer.scss";
import del from "../../Assets/SideBar-Image/del.png";
import { FC } from "react";

interface FooterProps {
  isUpdate?: boolean;
  footerDeleteEvent?: () => void;
  isSubmit?: any;
  isAddEvent?: boolean;
  isVenues?: boolean;
  cancelSubmit?: () => void;
  isValid?: boolean;
  dirty?: boolean;
  isSponsor?: boolean;
  isJobs?: boolean;
  isBanner?: boolean;
  isFlag?: any;
  setIsFlag?: any;
}

const Footer: FC<FooterProps> = ({
  isUpdate,
  footerDeleteEvent,
  isSubmit,
  isAddEvent = true,
  isVenues,
  cancelSubmit,
  isValid,
  dirty,
  isSponsor,
  isJobs,
  isBanner,
  isFlag,
  setIsFlag,
}) => {
  const handleDeleteClick = () => {
    footerDeleteEvent?.();
  };

  const handleSubmitClick = () => {
    isSubmit && isSubmit();
  };

  return (
    <div className="footers">
      <div className="footer-box">
        <div className="delte-btn">
          {!isAddEvent && (
            <button
              className="footer-delete-btn"
              type="button"
              onClick={handleDeleteClick}
            >
              <div className="Delete-footer">
                <img src={del} alt="" className="fa-solid fa-trash-can" />
                <span className="deletepakagestosave">
                  {isSponsor
                    ? "DELETE SPONSOR"
                    : isVenues
                    ? "DELETE VENUES"
                    : isJobs
                    ? "DELETE JOBS"
                    : isBanner
                    ? "DELETE BANNER"
                    : "DELETE EVENTS"}
                </span>
              </div>
            </button>
          )}
        </div>
        <div className="back-save-btns">
          <div className="back-btn">
            <button
              className="back-button"
              onClick={cancelSubmit}
              type="button"
            >
              BACK
            </button>
          </div>
          <div className="save-btn">
            <button
              className="save-button"
              type="submit"
              onClick={handleSubmitClick}
            >
              {isUpdate ? "UPDATE" : "SAVE"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
