import React from "react";
import { Link, useNavigate } from "react-router-dom";
import backimage from "../../../Assets/SideBar-Image/ic-header-back.png";
interface Iprops {
  id: any;
}
const ApplicentHeader: React.FC<Iprops> = ({ id }) => {
  const navigate = useNavigate();
  return (
    <div className="header">
      <nav className="navbar-child applicantHeader">
        <div className="content-Child-Headers cityChildHeader">
          <div className="ApplicentHeader">
            <div className="backimage">
              <Link to="/jobs">
                <img src={backimage} alt="" className="back-arrow" />
              </Link>
            </div>
            <div className="CitiesDateTimeRes CitiesHeight">
              <div className="child-Header-content">
                <a className="navbarBrand-childeheaders">Applicant Details</a>
              </div>
            </div>
          </div>
        </div>
        <div className="HeaderArchive">
          <button
            onClick={() => navigate(`/archived/${id}`)}
            className="ArchiveBtn"
          >
            Archived List
          </button>
        </div>
      </nav>
    </div>
  );
};

export default ApplicentHeader;
