import { ErrorMessage } from "formik";
import "./AddMedia.style.scss";
import del from "../../Assets/SideBar-Image/del.png";
import downArrow from "../../Assets/SideBar-Image/down-arrow.png";
import moment from "moment";
import { GenericConfirm } from "../../Utills/ConfirmAlerts";
import DatePicker from "react-datepicker";
import Select from "react-select";
import NoData from "../../Utills/NoData/NoData";
import infoIcon from "../../Assets/SideBar-Image/information-button.png";

function AddTicket(props: any) {
  const { values, setValues } = props;

  // handle click event of the Remove button
  const handleRemoveClick = (index: any) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    const list = [...values];

    list.unshift({
      name: "",
      link: "",
      price: "",
      price1: "",
      reducePrice: "",
      reducePriceDate: null,
      reducePriceTime: null,
      promoCode: "",
      type: "GA",
      reducePriceTicketLink: "",
      isReducedPrice: false,
      sponsorId: null,
      sponsor: null,
    });

    setValues(list);
  };

  return (
    <div>
      <div className="add-tickets">
        <div className="add-tickets-btn">
          <button
            className="addd-button"
            type="button"
            onClick={handleAddClick}
          >
            ADD TICKETS
          </button>
          <div className="hrline" />
        </div>
      </div>

      {values?.length > 0 ? (
        values.map((item: any, i: any) => {
          return (
            <div key={i} className="bookingsandpayments">
              <div className="bookings-box">
                <div className="tickets-name">
                  <label className="CommonLabel">TICKET NAME</label>
                  <input
                    value={item.name}
                    onChange={(e) => {
                      const list = [...values];
                      list[i].name = e.target.value;
                      setValues(list);
                    }}
                  />
                </div>

                <div className="tic-links">
                  <label className="CommonLabel">TICKET LINK</label>
                  <input
                    value={item.link}
                    onChange={(e) => {
                      const list = [...values];
                      list[i].link = e.target.value;
                      setValues(list);
                    }}
                  />
                </div>
                <div className="priceSection">
                  <div className="price">
                    <label className="CommonLabel">MIN PRICE</label>
                    <input
                      value={item.price}
                      placeholder="$33"
                      onChange={(e) => {
                        const list = [...values];
                        list[i].price = e.target.value;
                        setValues(list);
                      }}
                    />
                  </div>
                  <div className="price">
                    <label className="CommonLabel">MAX PRICE</label>
                    <input
                      value={item.price1}
                      onChange={(e) => {
                        const list = [...values];
                        list[i].price1 = e.target.value;
                        setValues(list);
                      }}
                    />
                  </div>
                </div>

                <div className="Notify-message">
                  <p className="ticketPriceNote">
                    <span className="mx-2">
                      <img src={infoIcon} />
                    </span>
                    <span className="priceNote">
                      If you want to add only one price then please add max
                      price same as the min price.
                    </span>
                  </p>
                </div>

                <div className="change-price-presale">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        {<img src={downArrow} alt="" className="downArrow" />}
                        <button
                          className="accordion-button collapsed CommonLabel PresalessContent"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseTwo${i}`}
                          aria-expanded="false"
                          aria-controls={`collapseTwo${i}`}
                        >
                          Change Price/Presale
                        </button>
                        <div>
                          <button
                            className="deleteTicket"
                            type="button"
                            onClick={() => {
                              GenericConfirm({
                                key: i,
                                saveCallback: handleRemoveClick,
                                triggerMsg:
                                  "Are you sure, you want to delete this Ticket?",
                                SaveMsg: "Okay",
                              });
                            }}
                          >
                            <img src={del} className="deleteImg" />
                            DELETE TICKET
                          </button>
                        </div>
                      </h2>
                      <div
                        id={`collapseTwo${i}`}
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="change-price">
                            <div className="change-price-to">
                              <label className="CommonLabel">
                                CHANGE PRICE TO
                              </label>
                              <input
                                value={item.reducePrice}
                                onChange={(e) => {
                                  const list = [...values];
                                  list[i].reducePrice = e.target.value;
                                  setValues(list);
                                }}
                                placeholder="$33"
                              />
                            </div>
                            <div className="afte-after-date-times changedPrice">
                              <label className="CommonLabel">After</label>
                              <DatePicker
                                className="calenderIcon"
                                selected={item.reducePriceDate}
                                onChange={(date) => {
                                  const list = [...values];
                                  list[i].reducePriceDate = date;
                                  setValues(list);
                                }}
                                dateFormat="MM/dd/y"
                                minDate={moment().toDate()}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                autoComplete="off"
                              />
                            </div>
                            <div className="afte-after-date-times">
                              <label className="CommonLabel">&nbsp;</label>
                              <DatePicker
                                className="timeIcon"
                                selected={item.reducePriceTime}
                                onChange={(date) => {
                                  const list = [...values];
                                  list[i].reducePriceTime = date;
                                  setValues(list);
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={1}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="NoData_margin">
          <NoData content="No ticket is found." />
        </div>
      )}
    </div>
  );
}

export default AddTicket;
