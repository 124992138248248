import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SecondFooter from "../../Component/Footer/SecondFooter";
import CitiesChildHeader from "../../Component/Header/ChildHeader/CitiesChildHeader";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import "./CitiesChild.scss";
import Loader from "../../Utills/Loader/Loader";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function CitesChild() {
  const axiosInstance = useApiService();
  const [loading, setLoading] = useState(true);
  const currentLocation = useLocation();
  const [citiesupdatedBy, setCitiesupdatedBy] = useState([]);
  const { name, timezone, updatedAt, _id } = currentLocation.state;

  const [updateCities, setUpdateCities] = useState({
    name,
    timezone,
  });
  const navigate = useNavigate();

  const updatecitiesChild = () => {
    if (updateCities.name.length === 0) {
      ToastFailure("Please Enter the Name");
      return;
    }
    if (updateCities.timezone.length === 0) {
      ToastFailure("Please Enter the timezone");
      return;
    }

    setLoading(true);
    axiosInstance
      .put(`admin/city`, {
        name: updateCities.name,
        timezone: updateCities.timezone,
        _id,
      })
      .then((response) => {
        ToastSuccess(response.data.message);
        setLoading(false);
        navigate("/city", { replace: true });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const getCitiesChild = async () => {
    setLoading(true);
    await axiosInstance
      .get(`admin/city?_id=${_id}`)
      .then((response) => {
        setCitiesupdatedBy(response.data.data.updatedBy);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getCitiesChild();
  }, [_id]);

  return (
    <>
      <CitiesChildHeader updatedAt={updatedAt} updatedBy={citiesupdatedBy} />

      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <>
          <div className="citiesChild-container TopCommonChild MainClass">
            <div className="citiesChild">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="cities-Child">
                      <div className="name">
                        <label htmlFor="/" className="Name">
                          NAME
                        </label>
                        <input
                          type="text"
                          name="Name"
                          value={updateCities.name}
                          placeholder="Name"
                          className="Timezone-name"
                          onChange={(e) =>
                            setUpdateCities({
                              ...updateCities,
                              name: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="Lname">
                        <label htmlFor="/" className="Timezone">
                          Timezone
                        </label>
                        <input
                          type="text"
                          name="Timezone"
                          value={updateCities.timezone}
                          placeholder="Timezone"
                          className="Timezone-Timezone"
                          onChange={(e) =>
                            setUpdateCities({
                              ...updateCities,
                              timezone: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SecondFooter
            cancelSubmit={() => navigate("/city")}
            isSubmit={updatecitiesChild}
            isUpdate
          />
        </>
      )}
    </>
  );
}

export default CitesChild;
