import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Footer from "../../../Component/Footer/Footer";
import JobsChildHeader from "../../../Component/Header/ChildHeader/JobsChildHeader";
import "./JobsChild.Style.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ToastFailure, ToastSuccess } from "../../../Share/toast/ToastMsg";
import Loader from "../../../Utills/Loader/Loader";
import { isEmail, isRichTextContextEmpty } from "../../../Utills/utils";
import DeleteIcon from "../../../Assets/SideBar-Image/del.png";
import { ConfirmDelete } from "../../../Utills/ConfirmAlerts";
import useApiService from "../../../Utills/ApiAxiosHandler/ApiAxiosHandler";
import RichTag from "../../../Utills/RichTag/RichTage";

function JobsChild() {
  const axiosInstance = useApiService();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const [childJobsDetails, setChildJobsDetails] = useState<any>({
    description: "",
    email: "",
    isPublished: false,
    title: "",
    jobCategoryId: "",
    question: [],
  });

  const handleSelectCategory = (e: any) => {
    setChildJobsDetails({
      ...childJobsDetails,
      jobCategoryId: e.target.value,
    });
  };

  const noEmptyStrings = (objArray: any) => {
    const stringArray = objArray.map((e: any) => {
      return e.question;
    });
    return stringArray.every((str: string) => str !== "");
  };

  // Get Category list
  const getCategoryList = async () => {
    const response = await axiosInstance.get(`admin/jobCategory?isActive=true`);
    setCategoryList(response?.data?.data);
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  const getAlljobsDetails = async () => {
    if (id) {
      setLoading(true);
      await axiosInstance
        .get(`admin/jobsById?_id=${id}`)
        .then((response) => {
          const jobDetail = response?.data?.data[0];
          setChildJobsDetails({
            jobCategoryId: jobDetail.jobCategoryId || "",
            description: jobDetail.description || "",
            email: jobDetail.email,
            isPublished: jobDetail.isPublished,
            title: jobDetail.title,
            question: jobDetail.jobQuestions,
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const addJob = () => {
    setLoading(true);
    axiosInstance
      .post(`admin/jobs`, {
        title: childJobsDetails.title,
        description: childJobsDetails.description,
        email: childJobsDetails.email,
        isPublished: childJobsDetails.isPublished,
        jobCategoryId: childJobsDetails.jobCategoryId,
        question: childJobsDetails.question,
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status == 1) {
          ToastSuccess(response.data.message);
          navigate("/jobs", { replace: true });
        } else {
          ToastFailure(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const updateJob = () => {
    setLoading(true);
    axiosInstance
      .put(`admin/jobs`, {
        _id: id,
        title: childJobsDetails.title,
        description: childJobsDetails.description,
        email: childJobsDetails.email,
        isPublished: childJobsDetails.isPublished,
        jobCategoryId: childJobsDetails.jobCategoryId,
        question: childJobsDetails.question,
      })
      .then((response) => {
        setLoading(false);

        if (response.data.status == 1) {
          ToastSuccess(response.data.message);
          navigate("/jobs", { replace: true });
        } else {
          ToastFailure(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const submitHandler = () => {
    if (!childJobsDetails.title.trim()) {
      ToastFailure("Please enter title");
      return;
    }

    if (childJobsDetails.isPublished) {
      if (!childJobsDetails.jobCategoryId) {
        ToastFailure("Please select category");
        return;
      }

      if (!childJobsDetails.email.trim()) {
        ToastFailure("Please enter email address");
        return;
      }

      if (
        childJobsDetails.email.trim() &&
        !isEmail(childJobsDetails.email.trim())
      ) {
        ToastFailure("Please enter valid email address");
        return;
      }

      if (isRichTextContextEmpty(childJobsDetails.description)) {
        ToastFailure("Please enter description");
        return;
      }

      if (childJobsDetails?.question?.length === 0) {
        ToastFailure("Please add atleast one question");
        return;
      }

      if (
        childJobsDetails?.question?.length > 0 &&
        !noEmptyStrings(childJobsDetails?.question)
      ) {
        ToastFailure("Question filed can not be empty");
        return;
      }
    }

    if (id) {
      updateJob();
    } else {
      addJob();
    }
  };

  function questionChangeHandler(i: any, event: any) {
    const questionArray: any = [...childJobsDetails.question];
    questionArray[i].question = event.target.value;
    setChildJobsDetails({
      ...childJobsDetails,
      question: questionArray,
    });
  }

  const questionAddHandler = () => {
    const questionArray = [...childJobsDetails.question];
    questionArray.push({ question: "" });
    setChildJobsDetails({
      ...childJobsDetails,
      question: questionArray,
    });
  };

  const deleteQuestion = (id: any) => {
    setLoading(true);
    axiosInstance
      .delete(`admin/jobQuestionDelete`, {
        data: {
          _id: id,
        },
      })
      .then((res) => {
        setLoading(false);
        ToastSuccess(res.data.message);

        const index = childJobsDetails.question.findIndex((e: any) => {
          return e._id === id;
        });
        const questionArray = [...childJobsDetails.question];
        questionArray.splice(index, 1);
        setChildJobsDetails({
          ...childJobsDetails,
          question: questionArray,
        });
      })
      .catch((err) => {
        setLoading(false);
        ToastFailure(err.message);
      });
  };

  function questionRemoveHandler(i: any, field: any) {
    if (field?._id) {
      ConfirmDelete(field?._id, deleteQuestion);
    } else {
      const questionArray = [...childJobsDetails.question];
      questionArray.splice(i, 1);
      setChildJobsDetails({
        ...childJobsDetails,
        question: questionArray,
      });
    }
  }

  const inputChangeHandler = (e: any) => {
    const { name, value } = e.target;
    setChildJobsDetails({
      ...childJobsDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    getAlljobsDetails();
  }, []);

  return (
    <>
      <JobsChildHeader
        title={id ? childJobsDetails.title : "Add Job"}
        setChildJobsDetails={setChildJobsDetails}
        isPublished={id ? childJobsDetails.isPublished : false}
      />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <>
          <div className="container MainClass">
            <div className="jobsChild TopCommonChild">
              <Form.Label className="CommonLabel">Select Category</Form.Label>
              <select
                className="jobSelector"
                name="value"
                value={childJobsDetails.jobCategoryId}
                onChange={handleSelectCategory}
              >
                <option value={""} disabled>
                  Select an option
                </option>
                {categoryList?.map((list: any, index: number) => {
                  return (
                    <option key={index} value={list._id}>
                      {list.categoryName}
                    </option>
                  );
                })}
              </select>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="CommonLabel">Job Name</Form.Label>
                  <Form.Control
                    className="sponser-sketck"
                    type="text"
                    placeholder="Name"
                    value={childJobsDetails.title}
                    name="title"
                    onChange={inputChangeHandler}
                    maxLength={50}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="CommonLabel">Email</Form.Label>
                  <Form.Control
                    type="email"
                    className="sponser-sketck"
                    placeholder="Email"
                    name="email"
                    value={childJobsDetails.email}
                    onChange={inputChangeHandler}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="CommonLabel">DESCRIPTION</Form.Label>
                  <RichTag
                    editerDetails={childJobsDetails.description}
                    setEditerDetails={(value: any) => {
                      setChildJobsDetails({
                        ...childJobsDetails,
                        description: value,
                      });
                    }}
                  />
                  {childJobsDetails?.question?.map((field: any, idx: any) => {
                    return (
                      <div className="questionRow" key={`${field}-${idx}`}>
                        <div>
                          <Form.Label className="mt-3 CommonLabel">
                            Question {idx + 1}
                          </Form.Label>
                        </div>
                        <div className="QuestionInput">
                          <input
                            type="text"
                            className="sponser-sketck form-control"
                            placeholder="Enter Question"
                            value={field.question}
                            onChange={(e) => questionChangeHandler(idx, e)}
                            maxLength={200}
                          />
                          <button
                            type="button"
                            onClick={() => questionRemoveHandler(idx, field)}
                          >
                            <span className="deleteIcon">
                              <img src={DeleteIcon} alt="" />
                            </span>
                            Delete
                          </button>
                        </div>
                      </div>
                    );
                  })}
                  <button
                    className="ArchiveBtn m-0 mt-3"
                    onClick={() => questionAddHandler()}
                    type="button"
                  >
                    Add Question
                  </button>
                </Form.Group>
              </Form>
            </div>
          </div>
          <Footer isSubmit={submitHandler} isUpdate={!!id} />
        </>
      )}
    </>
  );
}

export default JobsChild;
