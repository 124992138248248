import "../Faq.style.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastFailure, ToastSuccess } from "../../../Share/toast/ToastMsg";
import useApiService from "../../../Utills/ApiAxiosHandler/ApiAxiosHandler";

interface Iprops {
  modalShow: any;
  setModalShow: any;
  sectionName: any;
  type: any;
}

const FaqMobal: React.FC<Iprops> = ({
  modalShow,
  setModalShow,
  sectionName,
  type,
}) => {
  const axiosInstance = useApiService();
  const [faqAdd, setFaqAdd] = useState(sectionName);

  const navigate = useNavigate();
  const getAddFaqNew = () => {
    if (faqAdd.length == 0) {
      ToastFailure("Please Enter Section Title");
      return;
    }
    axiosInstance
      .post(`admin/section`, {
        name: faqAdd,
      })
      .then((response) => {
        setModalShow({ showModalFlag: false });
        navigate(`/FAQChild/${response.data._id}`, { state: { name: faqAdd } });
        ToastSuccess(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editFaq = () => {
    if (faqAdd.length == 0) {
      ToastFailure("Please Enter Section Title");
      return;
    }

    setModalShow({ showModalFlag: false, name: faqAdd });
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow({ showModalFlag: false })}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type === "add" ? "Add" : "Edit"} Section
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="faqpopup-contant">
          <div className="faqPopup-container">
            <div className="section-Name">
              <p className="section-title">Section Name</p>
              <input
                type="text"
                value={faqAdd}
                className="section-input-fields"
                onChange={(e) => setFaqAdd(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="FaqModalFooter">
        <Button
          onClick={() => setModalShow({ showModalFlag: false })}
          className="faq-cancel-btn"
        >
          CANCEL
        </Button>
        <Button
          onClick={(e: any) => {
            type === "add" ? getAddFaqNew() : editFaq();
          }}
          className="Faq-save-btn"
        >
          SAVE
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FaqMobal;
