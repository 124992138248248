import "./Header.style.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SidebarHeading } from "../../Store/Store";
import PropTypes from "prop-types";

interface Iprops {
  setSearch: any;
}
const VenuesHeader: React.FC<Iprops> = ({ setSearch }) => {
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();
  return (
    <div className="header">
      <nav className="navbar">
        <div className="content-Event">
          <div className="headding">
            <a className="navbarBrand">{SidebarHeading[1]}</a>
          </div>
          <div className="form VenueHeaderSec">
            <form className="d-flex" role="search">
              <div className="searchBar">
                <input
                  className="search"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div
                  className="searchicone SearchFeildMobile"
                  onClick={() => setVisible(!visible)}
                >
                  {visible ? (
                    <div className="searchicone new">
                      <i className="fa-sharp fa-solid fa-xmark fa-magnifying-glass" />
                    </div>
                  ) : (
                    <div className="searchicone">
                      <i className="fa-solid fa-magnifying-glass" />
                    </div>
                  )}
                </div>
                {visible && (
                  <div className="SearchFieldMobile">
                    <span>
                      <i className="fa-solid fa-magnifying-glass" />
                    </span>
                    <input
                      type="text"
                      name="text"
                      placeholder="Search"
                      defaultValue=""
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                )}

                <a className="searchicone">
                  <i className="fa-solid fa-magnifying-glass" />
                </a>
                <div className="addNewBtn">
                  <button
                    className="Addnew"
                    type="button"
                    onClick={() => navigate("/venuesChild/1")}
                  >
                    ADD NEW
                  </button>
                  <button
                    className="plusicon"
                    onClick={() => navigate("/venuesChild/1")}
                  >
                    <i className="fa-regular fa-plus" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
};

VenuesHeader.propTypes = {
  setSearch: PropTypes.any.isRequired,
};

export default VenuesHeader;
