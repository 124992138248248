import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Featured from "../../../Assets/SideBar-Image/ic-featured.png";
import backimage from "../../../Assets/SideBar-Image/ic-header-back.png";
interface Iprops {
  updatedAt: any;
  updatedBy: any;
  title: any;
  isFeatured: any;
  postVenuesChild: any;
  setPostVenuesChild: any;
  isLoading : any;
}
const ChiledVenuHeader: React.FC<Iprops> = ({
  updatedAt,
  updatedBy,
  title,
  isFeatured,
  postVenuesChild,
  setPostVenuesChild,
  isLoading
}) => {
  return (
    <div className="header">
      <nav className="navbar-child-venues">
        <div className="content-Child-Headers">
          <div className="headding-child-Headers">
            <div className="backimage">
              <Link to="/venues">
                <img src={backimage} alt="" className="back-arrow" />
              </Link>
            </div>
            <div className="venuesChild-updateAt">
              <div className="child-Header-content">
                <a className="navbarBrand-childeheaders">
                  {title.toUpperCase()}
                </a>
              </div>
              {title !== "Add Venue" && isLoading===false && (
                <div className="dateResponse">
                  <p className="lastEditeby">
                    Last edited: {moment(updatedAt).format("Do MMM YY LT")} by
                    {updatedBy}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="button-CHildvanu">
          <div
            className="featureVenueChildHeader"
            onClick={() =>
              setPostVenuesChild({
                ...postVenuesChild,
                isFeatured: !postVenuesChild.isFeatured,
              })
            }
          >
            <Link
              className={`${
                isFeatured ? ` btnFeatureActive` : `btnFeatureInactive`
              } `}
              to=""
            >
              <span className="headers-Feature-button">
                <img src={Featured} alt="/" />
                <span className="button-cnt">Featured</span>
              </span>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default ChiledVenuHeader;
