import { useState, useEffect } from "react";
import "./Notification.scss";
import moment from "moment/moment";
import Pagination from "react-js-pagination";
import NotificationHeader from "../../Component/Header/NotificationHeader";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function Notification() {
  const axiosInstance = useApiService();
  const [getNotification, setNotification] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  // const [flag, setFlag] = useState(false);
  const [sendNotify, setSendnotify] = useState("");
  const pageSize = 10;

  useEffect(() => {
    getTeamsData();
  }, [page]);

  useEffect(() => {
    getTeamsData();
  }, []);

  // get notification data
  const getTeamsData = async () => {
    const apiResponse = await axiosInstance.get(`admin/notification`, {
      params: { currentPage: page, pageSize, search: "" },
    });
    setNotification(apiResponse?.data?.data ?? []);
    setTotal(apiResponse?.data?.totalNotifications ?? 0);
  };

  const addNotify = () => {
    if (sendNotify.length > 0) {
      axiosInstance
        .post(`admin/notification`, {
          text: sendNotify,
        })
        .then((response) => {
          ToastSuccess(response?.data?.message);
          getTeamsData();
          setSendnotify("");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      ToastFailure("Add some content in notification");
    }
  };

  return (
    <>
      <NotificationHeader />
      <div className="notification-container TopCommon MainCLass">
        <div className="notification-area container">
          <div className="form-floating">
            <span className="notification-heading">NOTIFICATION</span>
            <textarea
              className="form-control"
              placeholder="Effective Advertising Pointers"
              value={sendNotify}
              onChange={(e) => setSendnotify(e.target.value)}
            />
          </div>
          <div className="button">
            <button
              className="Sent-btn btn"
              // flag={flag}
              // setFlag={setFlag}
              onClick={() => addNotify()}
            >
              SEND
            </button>
          </div>
        </div>
        <div className="container history-container">
          <div className="history">
            <p className="notification-heading">NOTIFICATION HISTORY</p>
            {getNotification ? (
              getNotification.map((item, index) => {
                const {
                  text,
                  totalCountAndroid,
                  totalCountIphone,
                  iphoneReadCount,
                  androidReadCount,
                  updatedAt,
                } = item;
                return (
                  <div className="history-box" key={index}>
                    <div className="Hist-heading">
                      <span className="heading">{text}</span>
                    </div>
                    <div className="history-content">
                      <div className="hist-dates&times">
                        <span className="Date&time">
                          {moment(updatedAt).format("Do MMM YY LT")}
                        </span>
                      </div>
                      <div className="android-count">
                        <span className="android">
                          android count ({totalCountAndroid})
                        </span>
                      </div>
                      <div className="andr-views">
                        <span className="view">
                          android views ({androidReadCount})
                        </span>
                      </div>
                      <div className="iphone-count">
                        <span className="iphone">
                          iphone count ({totalCountIphone})
                        </span>
                      </div>
                      <div className="iphone-view">
                        <span className="iph-view">
                          iphone views ({iphoneReadCount})
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
            {total > pageSize && (
              <Pagination
                activePage={page}
                itemsCountPerPage={pageSize}
                totalItemsCount={total}
                onChange={(page) => setPage(page)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;
