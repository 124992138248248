import { useState, useEffect } from "react";
import "./Events.style.scss";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import moment from "moment";
import packag from "../../Assets/SideBar-Image/ic-package.png";
import parking from "../../Assets/SideBar-Image/ic-parking.png";
import featured from "../../Assets/SideBar-Image/ic-featured.png";

import Header from "../../Component/Header/Header";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import Loader from "../../Utills/Loader/Loader";
import NoData from "../../Utills/NoData/NoData";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function Events() {
  const axiosInstance = useApiService();
  const [loading, setLoading] = useState(true);
  const [eventDetails, setEventDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortBy, sortByValue] = useState("recent");

  const pageSize = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getEventDetails();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [page, search, sortBy]);

  // get event details
  const getEventDetails = async () => {
    setLoading(true);
    await axiosInstance
      .get(`admin/events`, {
        params: { currentPage: page, pageSize, search, sortBy },
      })
      .then((response) => {
        setEventDetails(response?.data?.data ?? []);
        setTotal(response?.data?.totalEvent ?? 0);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // update featured
  const eventFeaturedUpdate = async (eventId: any, isFeatured: any) => {
    await axiosInstance
      .put(`admin/isFeatured`, { eventId, isFeature: isFeatured === 0 ? 1 : 0 })
      .then((response) => {
        if (response?.data?.status) {
          ToastSuccess(response?.data?.message);
          getEventDetails();
        } else {
          ToastFailure(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchHandler = (data: any) => {
    setSearch(data);
    setPage(1);
  };

  const sortHandler = (data: any) => {
    sortByValue(data);
    setPage(1);
  };

  return (
    <>
      <Header
        searchContent={search}
        setSearchContent={searchHandler}
        sortBy={sortBy}
        sortByValue={sortHandler}
      />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <div className="eventsMain-container TopCommon MainClass">
          <div className="events-main">
            {eventDetails && eventDetails?.length > 0 ? (
              eventDetails.map((item: any, index) => {
                const {
                  _id,
                  title,
                  subTitle,
                  startDate,
                  createdAt,
                  isPublished,
                  isFeatured,
                  isParking,
                  isPackage,
                } = item;
                return (
                  <div className="all-events-manage" key={index}>
                    <div
                      className={`events-contents-area ${
                        isPublished === 0 && "draftClr"
                      }`}
                      onClick={() => navigate(`/eventchild/${_id}`)}
                    >
                      <div className="datesandmonths">
                        <div className="monts-events">
                          {startDate
                            ? moment(startDate).format("MMM")
                            : moment(createdAt).format("MMM")}
                        </div>
                        <div className="dates-events">
                          {startDate
                            ? moment(startDate).format("DD")
                            : moment(createdAt).format("DD")}
                        </div>
                      </div>
                      <div className="events-parties-names">
                        <div className="events-and-parties">
                          <h5 className="parties-even">
                            {title} {isPublished === 0 && "(DRAFT)"}
                          </h5>
                          <p className="parties-thems">
                            {sortBy === "venue" ? item?.venue?.title : subTitle}
                          </p>
                        </div>
                        <div className="packagesandparking">
                          <div
                            className={`packages-eventsMain ${
                              isParking ? "button2" : "button1"
                            }`}
                          >
                            <span
                              className="eventButtons"
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/eventchild/${_id}?tabIndex=3`);
                              }}
                            >
                              <span className="package">
                                <img src={parking} alt="/" />
                                <span className="button-cnt">PARKING</span>
                              </span>
                            </span>
                          </div>
                          <div
                            className={`packages-eventsMain ${
                              isPackage ? "button2" : "button1"
                            }`}
                          >
                            <span
                              className="eventButtons"
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/eventchild/${_id}?tabIndex=3`);
                              }}
                            >
                              <span className="package">
                                <img src={packag} alt="/" />
                                <span className="button-cnt">PACKAGE</span>
                              </span>
                            </span>
                          </div>
                          <div
                            className={`packages-eventsMain ${
                              !isFeatured ? "button1" : "button3"
                            }`}
                          >
                            <span
                              className="eventButtons"
                              onClick={(e) => {
                                e.stopPropagation();
                                eventFeaturedUpdate(_id, isFeatured);
                              }}
                            >
                              <span className="package">
                                <img src={featured} alt="/" />
                                <span className="button-cnt">FEATURED</span>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <NoData content="No event found." />
            )}
          </div>
          {total > pageSize && (
            <Pagination
              activePage={page}
              itemsCountPerPage={pageSize}
              totalItemsCount={total}
              onChange={(page) => setPage(page)}
            />
          )}
        </div>
      )}
    </>
  );
}

export default Events;
