import "./Footer.scss";

function SecondFooter(props: any) {
  const { isUpdate, isSubmit, cancelSubmit } = props;
  return (
    <div className="footers">
      <div className="footer-box-second">
        <div className="back-save-btns">
          <div className="back-btn">
            <button className="back-button" onClick={() => cancelSubmit()}>
              BACK
            </button>
          </div>
          <div className="save-btn">
            <button
              className="save-button"
              type="submit"
              onClick={() => isSubmit()}
            >
              {isUpdate ? "Update" : "SAVE"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondFooter;
