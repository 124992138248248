import { useState, useEffect } from "react";
import SecondFooter from "../../Component/Footer/SecondFooter";
import TeamChildHeader from "../../Component/Header/ChildHeader/TeamChildHeader";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./TeamChild.scss";
import { useLocation, useNavigate } from "react-router";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";

import Loader from "../../Utills/Loader/Loader";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function TeamChild() {
  const axiosInstance = useApiService();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [userDetailsUpdate, setUserDetailsUpdate] = useState("");
  const { firstName, lastName, email, _id, updatedAt } = location.state;
  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const updateTeamMember = async (values: any) => {
    if (values) {
      const { firstName, lastName, email } = values;
      setLoading(true);
      const apiResponse = await axiosInstance.put(`admin/member`, {
        email,
        firstName,
        lastName,
        _id,
      });
      if (apiResponse?.data?.status) {
        setLoading(false);
        ToastSuccess(apiResponse?.data?.message);
        navigate("/team");
      } else {
        setLoading(false);
        ToastFailure(apiResponse?.data?.message);
      }
    }
  };

  const getTeamsChild = async () => {
    setLoading(true);
    await axiosInstance
      .get(`admin/member?_id=${_id}`)
      .then((response) => {
        setLoading(false);
        setUserDetailsUpdate(response.data.data.updatedBy);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getTeamsChild();
  }, [_id]);

  return (
    <>
      <TeamChildHeader updatedAt={updatedAt} updatedBy={userDetailsUpdate} />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <Formik
          initialValues={{
            firstName: firstName ?? "",
            lastName: lastName ?? "",
            email: email ?? "",
          }}
          enableReinitialize={true}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            // same shape as initial values
            updateTeamMember(values);
          }}
        >
          {({ errors, touched, handleChange, values }) => (
            <Form>
              <>
                <div className="teamChild-container TopCommonChild MainClass">
                  <div className="teamChild">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="clientdetails">
                            <div className="fname">
                              <label
                                htmlFor="/"
                                className="FirstName CommonLabel"
                              >
                                FIRST NAME
                              </label>
                              <input
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                className="FirstName"
                                onChange={handleChange}
                                value={values.firstName}
                              />
                              {errors.firstName && touched.firstName ? (
                                <div className="errorMsgs">
                                  {String(errors.firstName)}
                                </div>
                              ) : null}
                            </div>
                            <div className="Lname">
                              <label
                                htmlFor="/"
                                className="lastName CommonLabel"
                              >
                                LAST NAME
                              </label>
                              <input
                                type="text"
                                name="lastName"
                                placeholder="last Name"
                                className="lastName"
                                onChange={handleChange}
                                value={values.lastName}
                              />
                              {errors.lastName && touched.lastName ? (
                                <div className="errorMsgs">
                                  {String(errors.lastName)}
                                </div>
                              ) : null}
                            </div>
                            <div className="emails">
                              <label
                                htmlFor="/"
                                className="adminemail CommonLabel"
                              >
                                EMAIL
                              </label>
                              <input
                                name="email"
                                placeholder="Email"
                                className="Admin-Email-addd"
                                onChange={handleChange}
                                value={values.email}
                              />
                              {errors.email && touched.email ? (
                                <div className="errorMsgs">
                                  {String(errors.email)}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SecondFooter
                  isUpdate
                  cancelSubmit={() => navigate("/team")}
                  isSubmit={updateTeamMember}
                />
              </>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default TeamChild;
