import { useState } from "react";
import Button from "react-bootstrap/Button";
import TicketsModal from "../../Pages/Tickets/TicketsModal";
import { SidebarHeading } from "../../Store/Store";

const TicketsHeader: React.FC<any> = (props) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div>
      <div className="header TicketsHeader">
        <nav className="navbar">
          <div className="content-Event">
            <div className="headding">
              <a className="navbarBrand">{SidebarHeading[9]}</a>
            </div>
            <div className="form">
              <form className="d-flex" role="search">
                <div className="sectionBar">
                  <Button
                    variant="primary"
                    className="newTickets"
                    onClick={() => setModalShow(true)}
                  >
                    ADD NEW TICKET
                  </Button>

                  <TicketsModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    getTicketListHandler={props.getTicketListHandler}
                  />
                </div>
              </form>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default TicketsHeader;
