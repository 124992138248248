import React from 'react';
import { Link } from 'react-router-dom';
import backimage from '../../../Assets/SideBar-Image/ic-header-back.png';

function AddNewCitysHeader() {
  return (
    <div className="header">
      <nav className="navbar-child">
        <div className="content-Child-Headers">
          <div className="headding-child-Headers">
            <div className="AddCityHeader">
              <Link to="/city">
                <img src={backimage} alt="" className="back-arrow" />
              </Link>

              <div className="AddCityNav">
                <a className="navbarBrand-childeheaders">ADD CITY</a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default AddNewCitysHeader;
