import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import backimage from "../../../Assets/SideBar-Image/ic-header-back.png";

interface Iprops {
  updatedAt: any;
  updatedBy: any;
}
const TeamChildHeader: React.FC<Iprops> = ({ updatedAt, updatedBy }) => {
  return (
    <div>
      <div className="header">
        <nav className="navbar-child">
          <div className="content-Child-Headers">
            <div className="TeamSubHeader">
              <div className="backimage">
                <Link to="/team">
                  <img src={backimage} alt="" className="back-arrow" />
                </Link>
              </div>
              <div className="SubHeader">
                <a className="navbarBrand-childeheaders">EDIT ADMIN MEMBER</a>
                <p className="lastEditeby">
                  Last edited: {moment(updatedAt).format("Do MMM YY LT")} by{" "}
                  {updatedBy}
                </p>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default TeamChildHeader;
