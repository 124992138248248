import { useState, useEffect, useRef } from "react";
import "./Faq.style.scss";
import { useNavigate } from "react-router-dom";
import bars from "../../Assets/SideBar-Image/bars.png";

import del from "../../Assets/SideBar-Image/del.png";
import editIcon from "../../Assets/SideBar-Image/edit.png";
import FaqHeader from "../../Component/Header/FaqHeader";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import { ConfirmDelete } from "../../Utills/ConfirmAlerts";
import NoData from "../../Utills/NoData/NoData";
import Loader from "../../Utills/Loader/Loader";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function Faq(props: any) {
  const axiosInstance = useApiService();
  const [loading, setLoading] = useState(false);
  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();
  const [list, setlist] = useState([]);

  const dragStart = (e: any, position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    const copyListItems: any = [...list];
    const dragItemContent: any = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setlist(copyListItems);
    const filterDataBehalfId = copyListItems.map((item: any, index: any) => {
      return { _id: item._id, sequnceNumber: index };
    });
    dragFeq(filterDataBehalfId);
  };
  const navigate = useNavigate();

  const SectionArea = () => {
    setLoading(true);
    axiosInstance
      .get(`admin/sections`)
      .then((response) => {
        setLoading(false);
        setlist(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const onNavigate = (id: any, name: any) => {
    navigate(`/FAQChild/${id}`, { state: { name } });
  };
  const deleteFaq = async (_id: any) => {
    setLoading(true);
    await axiosInstance
      .delete(`admin/section`, {
        params: { _id },
      })
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          ToastSuccess("Successfully Deleted.");
          SectionArea();
        } else {
          ToastFailure(response?.data?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // drag & drop api call

  const dragFeq = async (data: any) => {
    await axiosInstance
      .put(`admin/sectionSequence`, {
        sectionIds: data,
      })
      .then((response) => {
        ToastSuccess("Sequence updated Successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    SectionArea();
  }, []);

  return (
    <>
      <FaqHeader />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <div className="Faq-container TopCommon MainClass">
          {list?.length > 0 ? (
            list.map((item: any, index) => {
              return (
                <div
                  className="faq"
                  onDragStart={(e) => dragStart(e, index)}
                  onDragEnter={(e) => dragEnter(e, index)}
                  onDragEnd={drop}
                  key={index}
                  draggable
                >
                  <div
                    className="faq-Box"
                    onClick={() => {
                      onNavigate(item._id, item.name);
                    }}
                  >
                    <ul className="content-item">
                      <li className="faq-faq">
                        <div className="content-faq">
                          <span className="moveicon">
                            <img src={bars} alt="" className="humbarger" />
                          </span>
                          <div className="heading-content-title">
                            <span className="faq-title">
                              <a className="include-parking">
                                <div className="faq-contents">
                                  <div className="title">{item.name}</div>
                                </div>
                              </a>
                            </span>
                          </div>
                        </div>

                        <button className="delete-btn">
                          <a
                            className="Delete-faq"
                            onClick={(e) => {
                              e.stopPropagation();
                              ConfirmDelete(item._id, deleteFaq);
                            }}
                          >
                            <img src={del} className="fa-solid fa-trash-can" />
                            <span className="delete-team-member">
                              DELETE SECTION
                            </span>
                          </a>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="faqSec">
              <NoData content="No section created yet." />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Faq;
