import React from "react";
import { useNavigate } from "react-router-dom";
import backimage from "../../../Assets/SideBar-Image/ic-header-back.png";

const NewBannerHeader = (props: any) => {
  const navigate = useNavigate();
  return (
    <div className="header">
      <nav className="navbar-child">
        <div className="content-Child-Headers">
          <div className="headding-child-Headers">
            <div className="backimage HeaderInline">
              <a onClick={() => navigate(`/home?tab=${props.tabIndex}`)}>
                <img src={backimage} alt="" className="back-arrow" />
              </a>
              <div className="HeaderWithDate">
                <a className="navbarBrand-childeheaders">{props.title}</a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NewBannerHeader;
