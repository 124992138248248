import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import backimage from "../../../Assets/SideBar-Image/ic-header-back.png";
interface Iprops {
  HeaderTitle: any;
  timeDate: any;
  id: any;
}
const HomeBannerChildHeader: React.FC<Iprops> = ({
  HeaderTitle,
  timeDate,
  id,
}) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="header HomeBannerChildSec">
        <nav className="navbar-child">
          <div className="content-Child-Headers">
            <div className="TeamSubHeader">
              <div className="backimage">
                <a onClick={() => navigate("/home?tab=1")}>
                  <img src={backimage} alt="" className="back-arrow" />
                </a>
              </div>

              <div className="child-Header-content">
                <a className="navbarBrand-childeheaders">
                  {HeaderTitle}
                  <div className="dateResponse">
                    {id && (
                      <p className="lastEditeby">
                        Last edited: {moment(timeDate).format("Do MMM YY LT")}{" "}
                        by admin
                      </p>
                    )}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default HomeBannerChildHeader;
