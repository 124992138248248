import { useRef, useState, useEffect } from "react";
import "./VenuesChild.scss";

import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import Footer from "../../Component/Footer/Footer";
import ChiledVenuHeader from "../../Component/Header/ChildHeader/ChiledVenuHeader";
import UploadEventImage from "./Modal/UploadEventImage";
import { ConfirmDelete } from "../../Utills/ConfirmAlerts";
import {
  extractImageFromUrl,
  isRichTextContextEmpty,
} from "../../Utills/utils";
import Loader from "../../Utills/Loader/Loader";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";
import RichTag from "../../Utills/RichTag/RichTage";

function VenuesChild() {
  const axiosInstance = useApiService();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const checkList = [
    "Visa",
    "Mastercard",
    "AmericanExpress",
    "Discover",
    "Cash",
  ];
  const [modalShow, setModalShow] = useState(false);
  const [citiesDropDown, setcitiesDropDown] = useState([]);
  const [activeTitle, setActiveTitle] = useState();

  const [postVenuesChild, setPostVenuesChild] = useState<any>({
    _id: id,
    address: "",
    bannerImage: "",
    boxOfficePhone: "",
    boxOfficeWorkingHours: "",
    city: "",
    description: "",
    directions: "",
    featureImage: "",
    info: "",
    isFeatured: "",
    seoDescription: "",
    seoTitle: "",
    sittingChartImage: [],
    termsAndRules: "",
    title: "",
    typeOfPayments: [],
    updatedAt: "",
    updatedBy: "",
    zip: "",
    galleryImages: [],
  });

  const navigate = useNavigate();

  const getVenuesChildDetails = async () => {
    if (Number(id) != 1) {
      setLoading(true);
      await axiosInstance
        .get(`${Number(id) != 1 && `admin/venue?_id=${id}`} `)
        .then((response) => {
          setLoading(false);
          setActiveTitle(response.data.data.title);
          setPostVenuesChild({
            _id: response.data.data._id,
            address: response.data.data.address,
            bannerImage: response.data.data.bannerImage,
            boxOfficePhone: response.data.data.boxOfficePhone,
            boxOfficeWorkingHours: response.data.data.boxOfficeWorkingHours,
            city: response.data.data.city,
            description: response.data.data.description || "",
            directions: response.data.data.directions || "",
            featureImage: response.data.data.featureImage,
            info: response.data.data.info || "",
            isFeatured: response.data.data.isFeatured,
            seoDescription: response.data.data.seoDescription,
            seoTitle: response.data.data.seoTitle,
            sittingChartImage: response.data.data.sittingChartImage,
            galleryImages: response.data.data.galleryImages,
            termsAndRules: response.data.data.termsAndRules || "",
            title: response.data.data.title,
            typeOfPayments: response.data.data.typeOfPayments,
            updatedAt: response.data.data.updatedAt,
            updatedBy: response.data.data.updatedBy,
            zip: response.data.data.zip,
          });
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const getCitiesSelect = async () => {
    await axiosInstance
      .get(`cities`)
      .then((response) => {
        setcitiesDropDown(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateVenues = async () => {
    if (postVenuesChild.title.length === 0) {
      ToastFailure("Please Enter Title");
      return;
    }
    if (postVenuesChild.boxOfficePhone.length === 0) {
      ToastFailure("Please enter phone number of Box office");
      return;
    }
    if (postVenuesChild.boxOfficeWorkingHours.length === 0) {
      ToastFailure("Please enter working office of Box office");
      return;
    }
    if (isRichTextContextEmpty(postVenuesChild.info)) {
      ToastFailure("Please enter info");
      return;
    }
    if (postVenuesChild.city.length == 0) {
      ToastFailure("Please Select the city");
      return;
    }
    if (postVenuesChild.address.length == 0) {
      ToastFailure("Please enter address");
      return;
    }
    if (!Number(postVenuesChild.zip)) {
      ToastFailure("Please Add valid zip-code");
      return;
    }

    if (isRichTextContextEmpty(postVenuesChild.description)) {
      ToastFailure("Please enter description");
      return;
    }
    if (isRichTextContextEmpty(postVenuesChild.directions)) {
      ToastFailure("Please enter directions");
      return;
    }
    if (isRichTextContextEmpty(postVenuesChild.termsAndRules)) {
      ToastFailure("Please enter termsAndRules");
      return;
    }
    if (postVenuesChild.typeOfPayments.length == 0) {
      ToastFailure("Please select atlest one type of payment mode");
      return;
    }
    if (postVenuesChild.sittingChartImage.length === 0) {
      ToastFailure("Please add sitting chart image.");
      return;
    }

    setLoading(true);
    axiosInstance
      .put(`admin/venue`, {
        address: postVenuesChild.address,
        bannerImage: postVenuesChild.bannerImage,
        boxOfficePhone: postVenuesChild.boxOfficePhone,
        boxOfficeWorkingHours: postVenuesChild.boxOfficeWorkingHours,
        city: postVenuesChild.city,
        description: postVenuesChild.description || "",
        directions: postVenuesChild.directions || "",
        featureImage: postVenuesChild.featureImage,
        info: postVenuesChild.info || "",
        isFeatured: postVenuesChild.isFeatured,
        seoDescription: postVenuesChild.seoDescription,
        seoTitle: postVenuesChild.seoTitle,
        sittingChartImage: postVenuesChild.sittingChartImage || [],
        termsAndRules: postVenuesChild.termsAndRules || "",
        title: postVenuesChild.title,
        typeOfPayments: postVenuesChild.typeOfPayments,
        updatedAt: postVenuesChild.updatedAt,
        updatedBy: postVenuesChild.updatedBy,
        zip: postVenuesChild.zip,
        _id: postVenuesChild._id,
        galleryImages: postVenuesChild.galleryImages || [],
      })
      .then((response) => {
        setLoading(false);
        navigate("/venues", { replace: true });
        ToastSuccess(response.data.message);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const footerDeleteEvent = async () => {
    setLoading(true);
    await axiosInstance
      .delete(`admin/venue?_id=${id}`)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          ToastSuccess("Successfully Deleted.");
          navigate("/venues", { replace: true });
          getVenuesChildDetails();
        } else {
          ToastFailure(response?.data?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const imageDeleteEvent = async (path: any, imageName: any) => {
    await axiosInstance
      .delete(`s3/remove-file`, {
        data: {
          image: `${path}/${imageName}`,
        },
      })
      .then((response) => {
        if (response?.data?.status) {
          ToastSuccess("Successfully Deleted.");
        } else {
          ToastFailure(response?.data?.error);
        }
      })
      .catch((error) => {
        console.log(error);
        ToastFailure(error?.data?.error);
      });
  };

  const deleteItem = (itemName: any, filePathName: any, flag: any) => {
    if (flag == 1) {
      const filterAwsChart = postVenuesChild.sittingChartImage.filter(
        (item: any) => item != itemName
      );
      imageDeleteEvent(filePathName, extractImageFromUrl(itemName));
      setPostVenuesChild({
        ...postVenuesChild,
        sittingChartImage: filterAwsChart,
      });
    } else {
      const filterAwsGallaryUrlCloud = postVenuesChild.galleryImages.filter(
        (item: any) => item != itemName
      );
      imageDeleteEvent(filePathName, extractImageFromUrl(itemName));
      setPostVenuesChild({
        ...postVenuesChild,
        galleryImages: filterAwsGallaryUrlCloud,
      });
    }
  };

  const addNewVenues = async () => {
    if (postVenuesChild.title.length === 0) {
      ToastFailure("Please Enter Title");
      return;
    }
    if (postVenuesChild.boxOfficePhone.length === 0) {
      ToastFailure("Please enter phone number of Box office");
      return;
    }
    if (postVenuesChild.boxOfficeWorkingHours.length === 0) {
      ToastFailure("Please enter working office of Box office");
      return;
    }
    if (isRichTextContextEmpty(postVenuesChild.info)) {
      ToastFailure("Please enter info");
      return;
    }
    if (postVenuesChild.city.length === 0) {
      ToastFailure("Please Select the city");
      return;
    }
    if (postVenuesChild.address.length === 0) {
      ToastFailure("Please enter address");
      return;
    }
    if (!Number(postVenuesChild.zip)) {
      ToastFailure("Please Add valid zip-code");
      return;
    }

    if (isRichTextContextEmpty(postVenuesChild.description)) {
      ToastFailure("Please enter description");
      return;
    }
    if (isRichTextContextEmpty(postVenuesChild.directions)) {
      ToastFailure("Please enter directions");
      return;
    }

    if (isRichTextContextEmpty(postVenuesChild.termsAndRules)) {
      ToastFailure("Please enter termsAndRules");
      return;
    }

    if (postVenuesChild.typeOfPayments <= 0) {
      ToastFailure("Please select atlest one type of payment mode");
      return;
    }
    if (postVenuesChild.bannerImage.length === 0) {
      ToastFailure("Please add banner image.");
      return;
    }
    if (postVenuesChild.featureImage.length === 0) {
      ToastFailure("Please Upload Feature image");
      return;
    }
    if (postVenuesChild.sittingChartImage.length == 0) {
      ToastFailure("Please add sitting chart image.");
      return;
    }

    setLoading(true);
    await axiosInstance
      .post(`admin/venue`, {
        address: postVenuesChild.address,
        bannerImage: postVenuesChild.bannerImage,
        boxOfficePhone: postVenuesChild.boxOfficePhone,
        boxOfficeWorkingHours: postVenuesChild.boxOfficeWorkingHours,
        city: postVenuesChild.city,
        description: postVenuesChild.description || "",
        directions: postVenuesChild.directions || "",
        featureImage: postVenuesChild.featureImage,
        info: postVenuesChild.info || "",
        isFeatured: postVenuesChild.isFeatured,
        seoDescription: postVenuesChild.seoDescription,
        seoTitle: postVenuesChild.seoTitle,
        sittingChartImage: postVenuesChild.sittingChartImage || [],
        termsAndRules: postVenuesChild.termsAndRules || "",
        title: postVenuesChild.title,
        typeOfPayments: postVenuesChild.typeOfPayments,
        zip: postVenuesChild.zip,
        galleryImages: postVenuesChild.galleryImages || [],
      })
      .then((response) => {
        setLoading(false);
        navigate("/venues", { replace: true });
        ToastSuccess(response.data.message);
      })
      .catch((error) => {
        setLoading(false);
        ToastFailure(error.response.data.message);
      });
  };

  useEffect(() => {
    getVenuesChildDetails();
    getCitiesSelect();
  }, [Number(id) != 1]);

  const handleChange = (name: any) => {
    const index = postVenuesChild.typeOfPayments.indexOf(name);
    const data = { ...postVenuesChild };
    if (index > -1) {
      data.typeOfPayments.splice(index, 1);
    } else {
      data.typeOfPayments.push(name);
    }
    setPostVenuesChild(data);
  };

  const checkHandler = (name: any) => {
    return postVenuesChild.typeOfPayments.indexOf(name) > -1;
  };

  return (
    <>
      <ChiledVenuHeader
        updatedAt={postVenuesChild.updatedAt ? postVenuesChild.updatedAt : ""}
        updatedBy={postVenuesChild.updatedBy ? postVenuesChild.updatedBy : ""}
        title={Number(id) == 1 ? `Add Venue` : activeTitle || ``}
        isFeatured={postVenuesChild.isFeatured}
        postVenuesChild={postVenuesChild}
        setPostVenuesChild={setPostVenuesChild}
        isLoading={loading}
      />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <>
          <div className="Venuese-Child-Container TopCommonChild MainClass">
            <div className="Child-Venues">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="inputTitlee">
                      <label className="CommonLabel">TITLE</label>
                      <input
                        type="text"
                        className="aura"
                        placeholder="Darling's Waterfront pavilion"
                        value={postVenuesChild.title}
                        onChange={(e) =>
                          setPostVenuesChild({
                            ...postVenuesChild,
                            title: e.target.value,
                          })
                        }
                        maxLength={50}
                      />
                    </div>
                    <div className="Box-office-Details">
                      <div className="box-offices">
                        <div className="boxOfficeHeading">
                          <label className="box-office-phone CommonLabel">
                            BOX OFFICE PHONE
                          </label>
                          <input
                            type="text"
                            name="phone"
                            maxLength={15}
                            className="timestophone"
                            placeholder="605-081-6464"
                            value={postVenuesChild.boxOfficePhone}
                            onChange={(e) =>
                              setPostVenuesChild({
                                ...postVenuesChild,
                                boxOfficePhone: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="office-working-hours">
                          <label className="workingHours CommonLabel">
                            BOX OFFICE WORKING HOURS
                          </label>
                          <input
                            type="text"
                            name="text"
                            className="timestoHours"
                            value={postVenuesChild.boxOfficeWorkingHours}
                            placeholder="Monday-Friday 9:00AM to 4:00PM"
                            onChange={(e) =>
                              setPostVenuesChild({
                                ...postVenuesChild,
                                boxOfficeWorkingHours: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="info-online-editor">
                      <div className="infoheadings">
                        <label className="infoHeadingsofonline CommonLabel">
                          INFO
                        </label>
                        <RichTag
                          editerDetails={postVenuesChild.info}
                          setEditerDetails={(value: any) => {
                            setPostVenuesChild({
                              ...postVenuesChild,
                              info: value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="detailsandAddress VenueFields">
                      <div className="cities">
                        <label className="CommonLabel">CITY</label>
                        <select
                          name="cities"
                          className="citiesnames"
                          value={postVenuesChild.city}
                          onChange={(e) => {
                            setPostVenuesChild({
                              ...postVenuesChild,
                              city: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled>
                            Please Select City
                          </option>
                          {citiesDropDown ? (
                            citiesDropDown.map((item: any, index) => {
                              return (
                                <option
                                  value={item._id}
                                  key={index}
                                  id={item._id}
                                >
                                  {item.name}
                                </option>
                              );
                            })
                          ) : (
                            <option value="" disabled>
                              Data not found..!
                            </option>
                          )}
                        </select>
                      </div>
                      <div className="addresses">
                        <div className="address">
                          <label className="CommonLabel">ADDRESS</label>
                          <input
                            type="text"
                            name="/"
                            className="realAddress"
                            value={postVenuesChild.address}
                            placeholder="786 Eve Garden  Apt. 693"
                            onChange={(e) =>
                              setPostVenuesChild({
                                ...postVenuesChild,
                                address: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="zips">
                        <div className="zipcode">
                          <label className="CommonLabel">ZIP</label>
                          <input
                            type="text"
                            name="text"
                            className="zipnumber"
                            value={postVenuesChild.zip}
                            placeholder="12345"
                            onChange={(e) =>
                              setPostVenuesChild({
                                ...postVenuesChild,
                                zip: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="Description-online-editor">
                      <div className="Descriptionheadings">
                        <label className="DescriptionHeadingsofonline CommonLabel">
                          DESCRIPTION
                        </label>
                        <div className="RichTextSec">
                          <RichTag
                            editerDetails={postVenuesChild.description}
                            setEditerDetails={(value: any) => {
                              setPostVenuesChild({
                                ...postVenuesChild,
                                description: value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="direction-online-editor">
                      <div className="directionheadings">
                        <label className="directionHeadingsofonline CommonLabel">
                          DIRECTIONS
                        </label>
                        <div className="RichTextSec">
                          <RichTag
                            editerDetails={postVenuesChild.directions}
                            setEditerDetails={(value: any) => {
                              setPostVenuesChild({
                                ...postVenuesChild,
                                directions: value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="termsandrules-online-editor">
                      <div className="termsandrulesheadings">
                        <label className="termsandrulesHeadingsofonline CommonLabel">
                          TERMS/RULES
                        </label>
                        <div className="RichTextSec">
                          <RichTag
                            editerDetails={postVenuesChild.termsAndRules}
                            setEditerDetails={(value: any) => {
                              setPostVenuesChild({
                                ...postVenuesChild,
                                termsAndRules: value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="seotypesheading">
                      <div className="seo">
                        <label className="titlesofseo CommonLabel">SEO</label>
                        <input
                          type="text"
                          name="/"
                          className="aurostreet"
                          value={postVenuesChild.seoTitle}
                          placeholder="Title"
                          onChange={(e) =>
                            setPostVenuesChild({
                              ...postVenuesChild,
                              seoTitle: e.target.value,
                            })
                          }
                        />
                        <input
                          type="text"
                          name="/"
                          className="centerstreet"
                          value={postVenuesChild.seoDescription}
                          placeholder="Description"
                          onChange={(e) =>
                            setPostVenuesChild({
                              ...postVenuesChild,
                              seoDescription: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="rightbar">
                      <div className="upload-btn">
                        <div className="upload-btn">
                          <div className="sketch-images">
                            <img
                              src={postVenuesChild.bannerImage}
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className="upload-btn">
                            <Button
                              className="uploadbtn"
                              onClick={() => {
                                setModalShow(true);
                              }}
                            >
                              UPLOAD IMAGES
                            </Button>
                            {modalShow && (
                              <UploadEventImage
                                bannerImage={postVenuesChild.bannerImage}
                                featureImage={postVenuesChild.featureImage}
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                deleteItem={deleteItem}
                                setCroppedBannerCloud={(e: any) => {
                                  setPostVenuesChild({
                                    ...postVenuesChild,
                                    bannerImage: e,
                                  });
                                }}
                                setCroppedFeature={(e: any) => {
                                  setPostVenuesChild({
                                    ...postVenuesChild,
                                    featureImage: e,
                                  });
                                }}
                                awsChartUrlCloud={
                                  postVenuesChild.sittingChartImage
                                }
                                setAwsChartUrlCloud={(e: any) => {
                                  setPostVenuesChild({
                                    ...postVenuesChild,
                                    sittingChartImage: e,
                                  });
                                }}
                                awsGallaryUrlCloud={
                                  postVenuesChild.galleryImages
                                }
                                setAwsGallaryUrlCloud={(e: any) => {
                                  setPostVenuesChild({
                                    ...postVenuesChild,
                                    galleryImages: e,
                                  });
                                }}
                              />
                            )}
                          </div>
                        </div>

                        {postVenuesChild.sittingChartImage.length > 0 && (
                          <div className="seatingscharts-venues">
                            <div className="seatingsCharts-box">
                              <h4 className="charts">SEATING CHART</h4>
                              <div className="charts-image">
                                <div className="containre">
                                  <div className="row">
                                    {postVenuesChild?.sittingChartImage.map(
                                      (item: any, index: any) => {
                                        return (
                                          <div className="col-sm-6" key={index}>
                                            <img
                                              src={item}
                                              alt="/"
                                              className="seating-chart-img"
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="payments">
                          <div className="paymentstypebox">
                            <h4 className="type-of-payments-accepts">
                              TYPES OF PAYMENT ACCEPTED
                            </h4>
                            <div className="typesofpayment">
                              {checkList.map((item, index) => {
                                return (
                                  <div key={index}>
                                    <input
                                      type="checkbox"
                                      onChange={() => handleChange(item)}
                                      defaultChecked={checkHandler(item)}
                                      id={`${index}`}
                                      className="form-check-input"
                                    />
                                    <span>{item}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        {postVenuesChild?.galleryImages?.length > 0 && (
                          <div className="seatingscharts-venues">
                            <div className="seatingsCharts-box">
                              <h4 className="charts">GALLERY</h4>
                              <div className="charts-image">
                                <div className="containre">
                                  <div className="row">
                                    {postVenuesChild?.galleryImages.map(
                                      (item: any, index: any) => {
                                        return (
                                          <div className="col-sm-6" key={index}>
                                            <img
                                              src={item}
                                              alt="/"
                                              className="seating-chart-img"
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer
            isAddEvent={Number(id) == 1}
            isUpdate={Number(id) != 1}
            isSubmit={Number(id) != 1 ? updateVenues : addNewVenues}
            footerDeleteEvent={() => {
              ConfirmDelete(id, footerDeleteEvent);
            }}
            isVenues
            cancelSubmit={() => navigate("/venues")}
          />
        </>
      )}
    </>
  );
}

export default VenuesChild;
