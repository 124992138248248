import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { SidebarHeading } from "../../Store/Store";
import PropTypes from "prop-types";

import "./Header.style.scss";
interface Iprops {
  setSearch: any;
  setSortedBy: any;
}
const SponserHeader: React.FC<Iprops> = ({ setSearch, setSortedBy }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const handleChange = (e: any) => {
    setSortedBy(e.target.value);
  };

  return (
    <div>
      <div className="header">
        <nav className="navbar">
          <div className="content-Event">
            <div className="headding">
              <NavLink to="/events" className="navbarBrand">
                {SidebarHeading[3]}
              </NavLink>
              <div className="dropdown d-flex">
                <span className="sort">Sort</span>
                <span className="by">by:</span>
                <div className="datepic">
                  <select className="selector" onChange={handleChange}>
                    <option value="name">Name</option>
                    <option value="venue">Venue</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form">
              <div className="searchBar">
                <input
                  className="search"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />

                <div
                  className="searchicone SearchFeildMobile"
                  onClick={() => setVisible(!visible)}
                >
                  {visible ? (
                    <div className="searchicone new">
                      <i className="fa-sharp fa-solid fa-xmark fa-magnifying-glass" />
                    </div>
                  ) : (
                    <div className="searchicone">
                      <i className="fa-solid fa-magnifying-glass" />
                    </div>
                  )}
                </div>
                {visible && (
                  <div className="SearchFieldMobile">
                    <span>
                      <i className="fa-solid fa-magnifying-glass" />
                    </span>
                    <input
                      type="text"
                      name="text"
                      placeholder="Search"
                      defaultValue=""
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                )}

                <a className="searchicone">
                  <i className="fa-solid fa-magnifying-glass" />
                </a>

                <div className="addNewBtn">
                  <button
                    className="Addnew"
                    type="button"
                    onClick={() => navigate("/SponserChild")}
                  >
                    ADD NEW
                  </button>
                  <button
                    className="plusicon"
                    onClick={() => navigate("/SponserChild")}
                  >
                    <i className="fa-regular fa-plus" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

SponserHeader.propTypes = {
  setSearch: PropTypes.any.isRequired,
  setSortedBy: PropTypes.any.isRequired,
};
export default SponserHeader;
