import { useState } from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";

interface Iprops {
  setSearch: any;
}
const JobsHeader: React.FC<Iprops> = ({ setSearch }) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="header">
      <nav className="navbar">
        <div className="content-Event">
          <div className="headding">
            <a className="navbarBrand">JOBS</a>
          </div>
          <div className="form HeaderForm">
            <div className="searchBar">
              <a className="searchicone">
                <i className="fa-solid fa-magnifying-glass" />
              </a>
              <input
                className="search"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
              {/* <<<<< Mobile view search feild UI i will do tommorow ( Don't remove )  >>>> */}

              <div
                className="searchicone SearchFeildMobile"
                onClick={() => setVisible(!visible)}
              >
                {visible ? (
                  <div className="searchicone new">
                    <i className="fa-sharp fa-solid fa-xmark fa-magnifying-glass" />
                  </div>
                ) : (
                  <div className="searchicone">
                    <i className="fa-solid fa-magnifying-glass" />
                  </div>
                )}
              </div>
              {visible && (
                <div className="SearchFieldMobile">
                  <span>
                    <i className="fa-solid fa-magnifying-glass" />
                  </span>
                  <input
                    type="text"
                    name="text"
                    placeholder="Search"
                    defaultValue=""
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              )}

              <div className="addNewBtn">
                <button
                  className="Addnew"
                  onClick={() => navigate(`/jobsdetails`)}
                >
                  ADD NEW
                </button>
                <button className="plusicon">
                  <i className="fa-regular fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

JobsHeader.propTypes = {
  setSearch: PropTypes.any.isRequired,
};
export default JobsHeader;
