import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Tickets.style.scss";
import { useState } from "react";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";
import RichTag from "../../Utills/RichTag/RichTage";
import { isRichTextContextEmpty } from "../../Utills/utils";

function TicketsModal(props: any) {
  const axiosInstance = useApiService();
  const [editerDetails, setEditerDetails] = useState<any>({
    content: "",
    title: "",
  });

  const addTickets = () => {
    if (editerDetails.title.length === 0) {
      ToastFailure("Please enter title");
      return;
    }
    if (isRichTextContextEmpty(editerDetails.content)) {
      ToastFailure("Please enter content");
      return;
    }
    axiosInstance
      .post(`admin/ticket-submenu`, {
        title: editerDetails.title,
        content: editerDetails.content,
      })
      .then((response) => {
        setEditerDetails({
          content: "",
          title: "",
        });
        props.onHide(false);
        ToastSuccess(response.data.message);
        props.getTicketListHandler();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Ticket SubMenu
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="popup-tickets-content-area">
          <div className="tickets-popup-container">
            <div className="tickets-popup-title">
              <p className="popup-title">Title</p>
              <input
                type="text"
                className="popup-input-fields"
                onChange={(e) =>
                  setEditerDetails({
                    ...editerDetails,
                    title: e.target.value,
                  })
                }
              />
            </div>
            <div className="popup-content-area">
              <div className="content-type">
                <p className="popup-content-head">Content</p>
                <RichTag
                  editerDetails={editerDetails.content}
                  setEditerDetails={(value: any) => {
                    setEditerDetails({
                      ...editerDetails,
                      content: value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setEditerDetails({
              content: "",
              title: "",
            });
            props.onHide();
          }}
          className="cancel-btn"
        >
          CANCEL
        </Button>
        <Button onClick={() => addTickets()} className="create-btn">
          CREATE
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default TicketsModal;
