import React from 'react';
import { Link } from 'react-router-dom';
import backimage from '../../../Assets/SideBar-Image/ic-header-back.png';

function AddNewTeamHeader() {
  return (
    <div className="header">
      <nav className="navbar-child">
        <div className="content-Child-Headers">
          <div className="TeamSubHeader">
            <div className="backimage">
              <Link to="/team">
                <img src={backimage} alt="" className="back-arrow" />
              </Link>
            </div>
            <div className="child-Header-content">
              <a className="navbarBrand-childeheaders">ADD ADMIN MEMBER</a>
              {/* <span className="lastEditeby">Last edited: 14 Jan 2023 at 1.08 by admin admin</span> */}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default AddNewTeamHeader;
