import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
// import getCroppedImg from "./cropImage";

function ImageCropper(props) {
  const { image, aspect, croppedArea, containerStyle } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  // IMPORTANT (Do not delete this code)
  //   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  //   const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback(async (croppArea, croppedAreaPixels) => {
    // setCroppedAreaPixels(croppedAreaPixels);
    croppedArea(croppedAreaPixels);
  }, []);
  // IMPORTANT (Do not delete this code)
  //   const showCroppedImage = useCallback(async () => {
  //     try {
  //       const croppedImage = await getCroppedImg(image, croppedAreaPixels);
  //     //   setCroppedImage(croppedImage);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   }, [croppedAreaPixels, image]);
  //   croppedImage ? (
  //     <img
  //       className="cropped-image"
  //       src={croppedImage}
  //       alt="cropped"
  //     />
  //   ) :

  return (
    <Cropper
      image={image}
      crop={crop}
      zoom={zoom}
      aspect={aspect}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
      style={{
        containerStyle,
        // mediaStyle: {},
        // cropAreaStyle: {},
      }}
    />
  );
}

export default ImageCropper;
