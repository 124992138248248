import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SecondFooter from "../../Component/Footer/SecondFooter";
import AddNewCitysHeader from "../../Component/Header/ChildHeader/AddNewCitysHeader";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import Loader from "../../Utills/Loader/Loader";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function AddCity() {
  const axiosInstance = useApiService();
  const [loading, setLoading] = useState(false);
  const [citiesAdd, setCitiesAdd] = useState({
    name: "",
    timezone: "",
  });

  const navigate = useNavigate();
  const AddCitiesNew = () => {
    if (citiesAdd.name.length == 0) {
      ToastFailure("Please Enter Name");
      return;
    }
    if (citiesAdd.timezone.length == 0) {
      ToastFailure("Please Add timezone");
      return;
    }

    setLoading(true);
    axiosInstance
      .post(`admin/city`, {
        name: citiesAdd.name,
        timezone: citiesAdd.timezone,
      })
      .then((response) => {
        setLoading(false);
        ToastSuccess(response.data.message);
        navigate("/city", { replace: true });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      <AddNewCitysHeader />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <>
          <div className="citiesChild-container TopCommonChild MainClass">
            <div className="citiesChild">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="cities-Child">
                      <div className="name">
                        <label htmlFor="/" className="Name">
                          NAME
                        </label>
                        <input
                          type="text"
                          name="Name"
                          placeholder="Name"
                          className="Timezone-name"
                          onChange={(e) =>
                            setCitiesAdd({
                              ...citiesAdd,
                              name: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="Lname">
                        <label htmlFor="/" className="Timezone">
                          Timezone
                        </label>
                        <input
                          type="text"
                          name="Timezone"
                          placeholder="Timezone"
                          className="Timezone-Timezone"
                          onChange={(e) =>
                            setCitiesAdd({
                              ...citiesAdd,
                              timezone: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SecondFooter
            cancelSubmit={() => navigate("/city")}
            isSubmit={AddCitiesNew}
          />
        </>
      )}
    </>
  );
}

export default AddCity;
