import { useState } from "react";
import "./Header.style.scss";
import { useNavigate } from "react-router-dom";
import { SidebarHeading } from "../../Store/Store";

function TeamHeader(props: any) {
  const [visible, setVisible] = useState(false);

  const { setSearchContent, searchContent } = props;
  const navigate = useNavigate();
  return (
    <div>
      <div className="header">
        <nav className="navbar">
          <div className="content-Event">
            <div className="headding">
              <a className="navbarBrand">{SidebarHeading[5]}</a>
            </div>

            <div className="searchBar TeamSearchinput">
              <input
                className="search"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchContent(e.target.value)}
                value={searchContent}
              />

              <div
                className="searchicone SearchFeildMobile"
                onClick={() => setVisible(!visible)}
              >
                {visible ? (
                  <div className="searchicone new">
                    <i className="fa-sharp fa-solid fa-xmark fa-magnifying-glass" />
                  </div>
                ) : (
                  <div className="searchicone">
                    <i className="fa-solid fa-magnifying-glass" />
                  </div>
                )}
              </div>
              {visible && (
                <div className="SearchFieldMobile">
                  <span>
                    <i className="fa-solid fa-magnifying-glass" />
                  </span>
                  <input
                    type="text"
                    name="text"
                    placeholder="Search"
                    defaultValue=""
                    onChange={(e) => setSearchContent(e.target.value)}
                  />
                </div>
              )}

              <a className="searchicone">
                <i className="fa-solid fa-magnifying-glass" />
              </a>
              <div className="addNewBtn">
                <button
                  className="Addnew"
                  type="button"
                  onClick={() => navigate("/AddNewTeam")}
                >
                  ADD NEW
                </button>
                <button
                  className="plusicon"
                  onClick={() => navigate("/AddNewTeam")}
                >
                  <i className="fa-regular fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default TeamHeader;
