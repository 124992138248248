import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SidebarHeading } from "../../Store/Store";
import "./Header.style.scss";

function Header(props: any) {
  const { setSearchContent, searchContent, sortByValue, sortBy } = props;
  const [visible, setVisible] = useState(false);

  const sortingOption = [
    { label: "Recent", value: "recent" },
    { label: "Name", value: "name" },
    { label: "Event Date", value: "date" },
    { label: "Venue", value: "venue" },
  ];

  const navigate = useNavigate();

  return (
    <div className="header">
      <nav className="navbar">
        <div className="content-Event">
          <div className="headding">
            <a className="navbarBrand">{SidebarHeading[0]}</a>
            <span className="sort">Sort</span>
            <span className="by">by:</span>
            <div className="datepic">
              <select
                className="selector"
                onChange={(e) => sortByValue(e.target.value)}
                value={sortBy}
              >
                {sortingOption.map((list, index) => (
                  <option key={index} value={list.value}>
                    {list.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="dropdown">
            <div className="form EventForm HeaderForm">
              <div className="searchBar">
                <input
                  className="search"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={(e) => setSearchContent(e.target.value)}
                  value={searchContent}
                />
                <a className="searchicone">
                  <i className="fa-solid fa-magnifying-glass" />
                </a>

                <div
                  className="searchicone SearchFeildMobile"
                  onClick={() => setVisible(!visible)}
                >
                  {visible ? (
                    <div
                      className="searchicone new"
                      onClick={() => setSearchContent("")}
                    >
                      <i className="fa-sharp fa-solid fa-xmark fa-magnifying-glass" />
                    </div>
                  ) : (
                    <div className="searchicone">
                      <i className="fa-solid fa-magnifying-glass" />
                    </div>
                  )}
                </div>
                {visible && (
                  <div className="SearchFieldMobile">
                    <span>
                      <i className="fa-solid fa-magnifying-glass" />
                    </span>
                    <input
                      type="text"
                      name="text"
                      placeholder="Search"
                      onChange={(e) => setSearchContent(e.target.value)}
                      value={searchContent}
                    />
                  </div>
                )}
                <div className="addNewBtn">
                  <button
                    className="Addnew"
                    type="button"
                    onClick={() => navigate("/eventchild")}
                  >
                    ADD NEW
                  </button>
                  <button
                    className="plusicon"
                    onClick={() => navigate("/eventchild")}
                  >
                    <i className="fa-regular fa-plus" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
