import { useState } from "react";
import SecondFooter from "../../Component/Footer/SecondFooter";
import AddNewTeamHeader from "../../Component/Header/ChildHeader/AddNewTeamHeader";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./TeamChild.scss";
import { useNavigate } from "react-router";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import Loader from "../../Utills/Loader/Loader";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function AddNewTeam() {
  const axiosInstance = useApiService();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .required("No password provided.")
      .min(6, "Password is too short - should be 6 chars minimum."),
    passwordConfirmation: Yup.string()
      .required("No password provided.")
      .oneOf(
        [Yup.ref("password"), null],
        "Passwords and Confirm password should be same"
      ),
  });

  const addTeamMember = async (values: any) => {
    if (values) {
      const { firstName, lastName, email, password } = values;
      setLoading(true);
      const apiResponse = await axiosInstance.post(`admin/member`, {
        email,
        firstName,
        lastName,
        password,
      });
      if (apiResponse?.data?.status) {
        setLoading(false);
        if (apiResponse?.data?.message === "Email already exists") {
          ToastFailure(apiResponse?.data?.message);
        } else {
          ToastSuccess(apiResponse?.data?.message);
          navigate("/team");
        }
      } else {
        setLoading(false);
        ToastFailure(apiResponse?.data?.message);
      }
    }
  };

  return (
    <>
      <AddNewTeamHeader />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            passwordConfirmation: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            // same shape as initial values
            addTeamMember(values);
          }}
        >
          {({ errors, touched, handleChange, values }) => (
            <Form>
              <>
                <div className="teamChild-container TopCommonChild MainClass">
                  <div className="teamChild">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="clientdetails">
                            <div className="fname">
                              <label
                                htmlFor="/"
                                className="FirstName CommonLabel"
                              >
                                FIRST NAME
                              </label>
                              <input
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                className="FirstName"
                                onChange={handleChange}
                                value={values.firstName}
                              />
                              {errors.firstName && touched.firstName ? (
                                <div className="errorMsgs">
                                  {errors.firstName}
                                </div>
                              ) : null}
                            </div>
                            <div className="Lname">
                              <label
                                htmlFor="/"
                                className="lastName CommonLabel"
                              >
                                LAST NAME
                              </label>
                              <input
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                className="lastName"
                                onChange={handleChange}
                                value={values.lastName}
                              />
                              {errors.lastName && touched.lastName ? (
                                <div className="errorMsgs">
                                  {errors.lastName}
                                </div>
                              ) : null}
                            </div>
                            <div className="emails">
                              <label
                                htmlFor="/"
                                className="adminemail CommonLabel"
                              >
                                EMAIL
                              </label>
                              <input
                                name="email"
                                placeholder="Email"
                                className="Admin-Email-addd"
                                onChange={handleChange}
                                value={values.email}
                              />
                              {errors.email && touched.email ? (
                                <div className="errorMsgs">{errors.email}</div>
                              ) : null}
                            </div>
                            <div className="emails">
                              <label
                                htmlFor="/"
                                className="adminemail CommonLabel"
                              >
                                PASSWORD
                              </label>
                              <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                className="Admin-Email-addd"
                                onChange={handleChange}
                                value={values.password}
                              />
                              {errors.password && touched.password ? (
                                <div className="errorMsgs">
                                  {errors.password}
                                </div>
                              ) : null}
                            </div>
                            <div className="emails">
                              <label
                                htmlFor="/"
                                className="adminemail CommonLabel"
                              >
                                RE-TYPE PASSWORD
                              </label>
                              <input
                                type="password"
                                name="passwordConfirmation"
                                placeholder="Confirm Password"
                                className="Admin-Email-addd"
                                onChange={handleChange}
                                value={values.passwordConfirmation}
                              />
                              {errors.passwordConfirmation &&
                              touched.passwordConfirmation ? (
                                <div className="errorMsgs">
                                  {errors.passwordConfirmation}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SecondFooter
                  cancelSubmit={() => navigate("/team")}
                  isSubmit={addTeamMember}
                />
              </>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default AddNewTeam;
