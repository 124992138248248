import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import backimage from "../../../Assets/SideBar-Image/ic-header-back.png";
interface Iprops {
  updatedAt: any;
  updatedBy: any;
}
const CitiesChildHeader: React.FC<Iprops> = ({ updatedAt, updatedBy }) => {
  return (
    <div className="header">
      <nav className="navbar-child">
        <div className="content-Child-Headers cityChildHeader">
          <div className="headding-child-Headers ">
            <div className="backimage">
              <Link to="/city">
                <img src={backimage} alt="" className="back-arrow" />
              </Link>
            </div>
            <div className="CitiesDateTimeRes CitiesHeight">
              <div className="child-Header-content">
                <a className="navbarBrand-childeheaders">EDIT CITY</a>
              </div>
              <div className="dateResponse">
                <span className="lastEditeby">
                  Last edited: {moment(updatedAt).format("Do MMM YY LT")} by{" "}
                  {updatedBy}
                </span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default CitiesChildHeader;
