import "./AddMedia.style.scss";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import NoData from "../../Utills/NoData/NoData";
import { ConfirmDelete } from "../../Utills/ConfirmAlerts";
import del from "../../Assets/SideBar-Image/del.png";

function AddPresale(props: any) {
  const { values, setValues } = props;

  const handleRemoveClick = (index: any) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
  };

  const handleAddClick = () => {
    const list = [...values];

    list.unshift({
      startPresaleDate: null,
      startPresaleTime: null,
      endPresaleDate: null,
      endPresaleTime: null,
      sponsor: "",
      sponsorId: "",
    });

    setValues(list);
  };

  return (
    <div>
      <div className="add-tickets">
        <div className="add-tickets-btn">
          <button
            className="addd-button"
            type="button"
            onClick={handleAddClick}
          >
            ADD PRESALES
          </button>
          <div className="hrline" />
        </div>
      </div>

      {values?.length > 0 ? (
        values.map((item: any, i: any) => {
          return (
            <div key={i} className="bookingsandpayments presaleEditSec">
              <div className="bookings-box">
                <div className="startPresale">
                  <div className="startPresale-content">
                    <div className="Presale-start">
                      <label className="CommonLabel">START PRESALE</label>
                    </div>
                    <div className="inputs-fields">
                      <DatePicker
                        className="calenderIcon"
                        selected={item.startPresaleDate}
                        onChange={(date) => {
                          const list = [...values];
                          list[i].startPresaleDate = date;
                          setValues(list);
                        }}
                        dateFormat="MM/dd/y"
                        minDate={moment().toDate()}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="startPresale-content">
                    <div className="Presale-start">
                      <label className="CommonLabel">&nbsp;</label>
                    </div>
                    <div className="inputs-fields">
                      <DatePicker
                        className="timeIcon"
                        selected={item.startPresaleTime}
                        onChange={(date) => {
                          const list = [...values];
                          list[i].startPresaleTime = date;
                          setValues(list);
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="EndPresale-content">
                    <div className="Endsale-start">
                      <label className="CommonLabel">END PRESALE</label>
                    </div>
                    <div className="endsale-inputs-fields">
                      <DatePicker
                        className="calenderIcon"
                        selected={item.endPresaleDate}
                        onChange={(date) => {
                          const list = [...values];
                          list[i].endPresaleDate = date;
                          setValues(list);
                        }}
                        dateFormat="MM/dd/y"
                        minDate={moment().toDate()}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="EndPresale-content">
                    <div className="Endsale-start">
                      <label className="CommonLabel">&nbsp;</label>
                    </div>
                    <div className="endsale-inputs-fields">
                      <DatePicker
                        className="timeIcon"
                        selected={item.endPresaleTime}
                        onChange={(date) => {
                          const list = [...values];
                          list[i].endPresaleTime = date;
                          setValues(list);
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="sponser-presale-tickets">
                  <div className="sponsess-inputs">
                    <div className="sponsers-presale-title">
                      <label className="CommonLabel">SPONSOR</label>
                      <Select
                        value={
                          item.sponsor && item.sponsor !== ""
                            ? {
                                _id: item.sponsorId,
                                name: item.sponsor,
                              }
                            : null
                        }
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option._id}
                        onChange={(selectedOption) => {
                          const list = [...values];
                          list[i].sponsor = selectedOption?.name;
                          list[i].sponsorId = selectedOption?._id;
                          setValues(list);
                        }}
                        options={props.sponsorOption}
                      />
                    </div>
                  </div>
                </div>
                <div className="btn-box">
                  <div className="del-buttons">
                    <button
                      className="delete-btn-tickets"
                      type="button"
                      onClick={() => ConfirmDelete(i, handleRemoveClick)}
                    >
                      <span className="Delete-tickets-package">
                        <img
                          alt=""
                          src={del}
                          className="fa-solid fa-trash-can"
                        />
                        <span className="delete-team-member">
                          DELETE PRESALE
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="NoData_margin">
          <NoData content="No presale is found." />
        </div>
      )}
    </div>
  );
}

export default AddPresale;
