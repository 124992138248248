import "./Header.style.scss";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import { SidebarHeading } from "../../Store/Store";
import FaqMobal from "../../Pages/FAQ/FaqMobal/FaqMobal";

const FaqHeader: React.FC<any> = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="header">
      <nav className="navbar">
        <div className="FaqHeader">
          <div className="headding">
            <a className="navbarBrand">{SidebarHeading[7]}</a>
          </div>
          <div className="form">
            <form className="d-flex" role="search">
              <div className="sectionBar">
                <div className="sectionBtn">
                  <Button
                    variant="primary"
                    className="FaqPopupbtn"
                    onClick={() => setModalShow(true)}
                  >
                    ADD NEW SECTION
                  </Button>

                  {modalShow && (
                    <FaqMobal
                      modalShow={modalShow}
                      setModalShow={(e: any) => {
                        setModalShow(e.showModalFlag);
                      }}
                      sectionName=""
                      type="add"
                    />
                  )}
                  <p className="plusicon" onClick={() => setModalShow(true)}>
                    <i className="fa-regular fa-plus" />
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default FaqHeader;
