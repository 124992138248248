import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import JobsHeader from "../../Component/Header/ChildHeader/JobsHeader";
import "./Jobs.Style.scss";
import {
  ConfirmChange,
  ConfirmDelete,
  GenericConfirm,
} from "../../Utills/ConfirmAlerts";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import Loader from "../../Utills/Loader/Loader";
import NoData from "../../Utills/NoData/NoData";
import parse from "html-react-parser";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";

function Jobs() {
  const axiosInstance = useApiService();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [jobsDetails, setJobsDetails] = useState<any>([]);
  const [search, setSearch] = useState("");

  const getjobsbox = () => {
    setLoading(true);
    axiosInstance
      .get(`admin/jobs`, {
        params: { search },
      })
      .then((response) => {
        setLoading(false);
        setJobsDetails(response?.data?.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const deleteJobsbyId = (id: any) => {
    setLoading(true);
    axiosInstance
      .delete(`admin/jobs`, {
        data: {
          _id: id,
        },
      })
      .then((res) => {
        setLoading(false);
        ToastSuccess(res.data.message);
        getjobsbox();
      })
      .catch((err) => {
        setLoading(false);
        ToastFailure(err.message);
      });
  };

  const updateJobs = async (item: any) => {
    const response = await axiosInstance.patch(`admin/jobs`, {
      isActive: !item.isActive,
      _id: item._id,
    });
    if (response.status == 200) {
      if (response?.data?.status === 1) {
        ToastSuccess(response?.data?.message);
        getjobsbox();
      } else {
        ToastFailure(response?.data?.message);
      }
    }
  };

  const handleStatusClick = (item: any) => {
    updateJobs(item);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getjobsbox();
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <>
      <JobsHeader setSearch={setSearch} />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <div className="Jobs-container">
          <div className="jobs-content">
            {jobsDetails?.length > 0 ? (
              jobsDetails.map((item: any, index: any) => {
                return (
                  <div className="jobs-Box" key={index}>
                    <ul
                      className={
                        item.isPublished
                          ? "jobs-content-item"
                          : "jobs-content-item draftClr"
                      }
                    >
                      <li className="jobs-listing">
                        <div className="jobs-content-Name">
                          <span className="moveicon" />
                          <div className="heading-jobs-title">
                            <span className="jobs-title">
                              <a className="Jobs-Feadback">
                                <div
                                  className="jobs-contents"
                                  onClick={() =>
                                    navigate(`/jobsdetails/${item?._id}`)
                                  }
                                >
                                  <div className="jobs">
                                    <p className="jobs-name">
                                      {item.title}
                                      {item.isPublished ? "" : "(DRAFT)"}
                                    </p>
                                    <p className="jobs-Description">
                                      {item?.description
                                        ? parse(item?.description)
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </span>
                          </div>
                        </div>
                        <div className="jobs-btns">
                          <div className="jobs-Applicent-btn">
                            <div className="jobs-buttons">
                              <span
                                className={`jobs-Applicent ${
                                  item?.jobApplyCount == 0
                                    ? `disable-no-applicient`
                                    : ``
                                }`}
                                onClick={(e) =>
                                  item?.jobApplyCount != 0
                                    ? navigate(`/applicent/${item?._id}`)
                                    : e.preventDefault()
                                }
                              >
                                <span className="button-jobs-Delete me-2">
                                  Applicant
                                </span>
                                [{item?.jobApplyCount}]
                              </span>
                            </div>
                          </div>
                          {item.isPublished && (
                            <div className="jobs-Disable-btn">
                              <div className="jobs-buttons">
                                <span
                                  className="jobs-Disable"
                                  onClick={() => {
                                    GenericConfirm({
                                      key: item,
                                      saveCallback: handleStatusClick,
                                      triggerMsg: `Are you sure, you want to ${
                                        item?.isActive ? "Disable" : "Enable"
                                      } ?`,
                                      SaveMsg: "Yes",
                                    });
                                  }}
                                >
                                  <i className="fa fa-ban" aria-hidden="true" />
                                  <span className="button-jobs-Disable">
                                    {item?.isActive ? "Disable" : "Enable"}
                                  </span>
                                </span>
                              </div>
                            </div>
                          )}

                          {(!item.isPublished || item?.jobApplyCount === 0) && (
                            <div className="jobs-Delete-btn">
                              <div className="jobs-buttons">
                                <span
                                  className="jobs-Delete"
                                  onClick={() => {
                                    ConfirmDelete(item?._id, deleteJobsbyId);
                                  }}
                                >
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  />
                                  <span className="button-jobs-Delete">
                                    Delete
                                  </span>
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                );
              })
            ) : (
              <NoData content="No job found." />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Jobs;
