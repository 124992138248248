import { useEffect, useState } from "react";
import "./EventChild.style.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import featured from "../../Assets/SideBar-Image/ic-featured.png";
import Footer from "../../Component/Footer/Footer";
import save from "../../Assets/SideBar-Image/ic-save.png";
import UploadEventImage from "./Modal/UploadEventImage";
import AddPresale from "./AddPresale";
import ChiledHeader from "../../Component/Header/ChildHeader/ChiledHeader";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import { ConfirmDelete, ConfirmVenueChange } from "../../Utills/ConfirmAlerts";
import "react-datepicker/dist/react-datepicker.css";
import AddPackageToEvent from "./AddPackage";
import AddMedia from "./AddMedia";
import AddTicket from "./AddTicket";
import Loader from "../../Utills/Loader/Loader";
import del from "../../Assets/SideBar-Image/del.png";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";
import RichTag from "../../Utills/RichTag/RichTage";
import {
  isRichTextContextEmpty,
  numberToWords,
  validateUrl,
} from "../../Utills/utils";

function EventChild() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabValue: any = searchParams.get("tabIndex");
  const axiosInstance = useApiService();
  const [loading, setLoading] = useState<any>(false);
  const [modalShow, setModalShow] = useState<any>(false);
  const { id } = useParams();
  const isAddEvent = id ? false : true;
  const isPackageRedirection = tabValue && tabValue == 3 ? true : false;

  // Venue dropdown
  const [venueDataOptions, setVenueDataOptions] = useState<any>([]);
  const [selectedVenueData, setSelectedVenueData] = useState<any>(null);

  // term and condition
  const [teamsAndRulesOption, setTeamsAndRulesOption] = useState([]);
  const [selectedTeamsAndRulesID, setSelectedTeamsAndRulesID] = useState(null);

  // Ticket sponser
  const [sponsorOption, setSponsorOption] = useState([]);

  // Package
  const [packageOption, setPackageOption] = useState([]);

  const [eventInfo, setEventInfo] = useState<any>({
    title: "",
    subTitle: "",
    termsAndRules: "",
    startDate: null,
    startTime: null,
    startDateTime: null,
    endDateTime: null,
    endDate: null,
    endTime: null,
    seoTitle: "",
    seoDescription: "",
    description: "",
    isFeatured: 0,
    isPublished: 0,
    onSaleDateTime: null,
    onSaleDate: null,
    onSaleTime: null,
    cutOffDateTime: null,
    cutOffDate: null,
    cutOffTime: null,
    doorTime: null,
    generalTicketLink: "",
    images: [],
    tickets: [],
    packages: [],
    media: [],
    preSales: [],
    venue: "",
    venueId: "",
    venueSittingChartImage: "",
    galleryImages: [],
    bannerImage: "",
    featureImage: "",
    typeOfPayments: [],
  });

  useEffect(() => {
    fetchVenueDetails();
    getTeamsAndRulesData();
    fetchEventSavedData();
  }, []);

  // Delete Template
  const deleteTemplate = async () => {
    if (selectedTeamsAndRulesID) {
      const response = await axiosInstance.delete(
        `admin/terms-and-rules?_id=${selectedTeamsAndRulesID}`
      );
      if (response.status == 200) {
        setEventInfo({
          ...eventInfo,
          termsAndRules: "",
        });
        ToastSuccess(response?.data?.message);
        getTeamsAndRulesData();
      }
    } else {
      ToastFailure("Tab(Terms/Rules):- please select template ");
    }
  };

  // get Sponsor Option
  const getSponsorOption = async (venueId: any) => {
    await axiosInstance
      .get(`admin/sponsor-list`, {
        params: { venueId: venueId },
      })
      .then((response) => {
        if (response?.data?.status) {
          setSponsorOption(response?.data?.data || []);
        } else {
          ToastFailure(response?.data?.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // get Teams And Rules Data
  const getTeamsAndRulesData = async () => {
    await axiosInstance
      .get(`admin/terms-and-rules`)
      .then((response) => {
        if (response?.data?.status) {
          setTeamsAndRulesOption(response?.data?.data ?? []);
        } else {
          ToastFailure(response?.data?.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchSavedPackageData = async (venueId: any) => {
    await axiosInstance
      .get(`admin/package-by-venue`, {
        params: { venueId },
      })
      .then((response) => {
        if (response?.data?.status) {
          setPackageOption(response?.data?.data || []);
        } else {
          ToastFailure(response?.data?.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchVenueDetails = async () => {
    setLoading(true);
    await axiosInstance
      .get(`admin/venue-list`, {
        params: { limit: 1000, search: "" },
      })
      .then((response) => {
        if (response?.data?.status) {
          response?.data?.data.forEach((obj: any) => {
            obj.label = obj.title;
            obj.value = obj._id;
          });
          setVenueDataOptions(response?.data?.data);
        } else {
          ToastFailure(response?.data?.error);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const fetchEventSavedData = async () => {
    if (!isAddEvent && id) {
      setLoading(true);
      await axiosInstance
        .get(`admin/event`, {
          params: { _id: id },
        })
        .then((response) => {
          if (response?.data?.status) {
            const responseData = response?.data?.data;

            const getObj = {
              title: responseData.title || "",
              subTitle: responseData.subTitle || "",
              termsAndRules: responseData.termsAndRules || "",
              startDate: responseData.startDate
                ? Number(responseData.startDate)
                : null,
              startTime: responseData.startTime
                ? Number(responseData.startTime)
                : null,
              endDate: responseData.endDate
                ? Number(responseData.endDate)
                : null,
              endTime: responseData.endTime
                ? Number(responseData.endTime)
                : null,
              seoTitle: responseData.seoTitle,
              seoDescription: responseData.seoDescription,
              description: responseData.description,
              isFeatured: responseData.isFeatured,
              isPublished: responseData.isPublished,
              onSaleDateTime: responseData.onSaleDateTime || null,
              onSaleDate: responseData.onSaleDate
                ? Number(responseData.onSaleDate)
                : null,
              onSaleTime: responseData.onSaleTime
                ? Number(responseData.onSaleTime)
                : null,
              cutOffDateTime: responseData.cutOffDateTime || null,
              cutOffDate: responseData.cutOffDate
                ? Number(responseData.cutOffDate)
                : null,
              cutOffTime: responseData.cutOffTime
                ? Number(responseData.cutOffTime)
                : null,
              doorTime: responseData.doorTime
                ? new Date(responseData.doorTime).getTime()
                : null,
              generalTicketLink: responseData.generalTicketLink || "",
              images: [],
              tickets: responseData.tickets || [],
              preSales: responseData.preSales || [],
              packages: responseData.packages || [],
              media: responseData.media || [],
              venue: responseData.venue || "",
              venueId: responseData.venueId || "",
              venueSittingChartImage: responseData.venueSittingChartImage || "",
              galleryImages: responseData.galleryImages || [],
              startDateTime: responseData.startDateTime || null,
              endDateTime: responseData.endDateTime || null,
              bannerImage: responseData.bannerImage || "",
              featureImage: responseData.featureImage || "",
              typeOfPayments: responseData.typeOfPayments || [],
            };

            if (getObj.tickets && getObj.tickets.length > 0) {
              for (let index = 0; index < getObj.tickets.length; index++) {
                const element = getObj.tickets[index];

                element.reducePriceDate = element.reducePriceDate
                  ? Number(element.reducePriceDate)
                  : null;
                element.reducePriceTime = element.reducePriceTime
                  ? Number(element.reducePriceTime)
                  : null;
              }
            }

            if (getObj.preSales && getObj.preSales.length > 0) {
              for (let index = 0; index < getObj.preSales.length; index++) {
                const element = getObj.preSales[index];

                element.startPresaleDate = element.startPresaleDate
                  ? Number(element.startPresaleDate)
                  : null;
                element.startPresaleTime = element.startPresaleTime
                  ? Number(element.startPresaleTime)
                  : null;
                element.endPresaleDate = element.endPresaleDate
                  ? Number(element.endPresaleDate)
                  : null;
                element.endPresaleTime = element.endPresaleTime
                  ? Number(element.endPresaleTime)
                  : null;
              }
            }

            setEventInfo(getObj);
            if (responseData.venueId) {
              setSelectedVenueData({
                label: responseData?.venue || "",
                value: responseData?.venueId || "",
                _id: responseData?.venueId || "",
                title: responseData?.venue || "",
                sittingChartImage: responseData?.sittingChartImage || [],
                typeOfPayments: responseData?.typeOfPayments || [],
              });
              fetchSavedPackageData(responseData.venueId);
              getSponsorOption(responseData.venueId);
            }
          } else {
            ToastFailure(response?.data?.error);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const saveTermsAndRules = async () => {
    await axiosInstance
      .post(`admin/terms-and-rules`, { termsAndRules: eventInfo.termsAndRules })
      .then((response: any) => {
        if (response?.data?.status) {
          ToastSuccess(response?.data?.message);
          getTeamsAndRulesData();
        } else {
          ToastFailure(response.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // Delete event
  const deleteEvent = async () => {
    if (!isAddEvent && id) {
      setLoading(true);
      await axiosInstance
        .delete(`admin/event`, {
          params: { _id: id },
        })
        .then((response) => {
          if (response?.data?.status) {
            setLoading(false);
            ToastSuccess("Successfully Deleted.");
            navigate("/events");
          } else {
            ToastFailure(response?.data?.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      ToastFailure("Event not found");
    }
  };

  // Add event
  const addEvent = async () => {
    const obj = {
      title: eventInfo.title,
      subTitle: eventInfo.subTitle,
      termsAndRules: eventInfo.termsAndRules,
      startDate: eventInfo.startDate
        ? Number(moment(eventInfo.startDate).utc().format("x"))
        : null,
      startTime: eventInfo.startTime
        ? Number(moment(eventInfo.startTime).utc().format("x"))
        : null,
      endDate: eventInfo.endDate
        ? Number(moment(eventInfo.endDate).utc().format("x"))
        : null,
      endTime: eventInfo.endTime
        ? Number(moment(eventInfo.endTime).utc().format("x"))
        : null,
      seoTitle: eventInfo.seoTitle,
      seoDescription: eventInfo.seoDescription,
      description: eventInfo.description,
      isFeatured: eventInfo.isFeatured,
      isPublished: eventInfo.isPublished,
      onSaleDateTime:
        eventInfo.onSaleDate && eventInfo.onSaleTime
          ? moment(eventInfo.onSaleDate).format("YYYY-MM-DD") +
            " " +
            moment(eventInfo.onSaleTime).format("HH:mm")
          : null,
      onSaleDate: eventInfo.onSaleDate
        ? Number(moment(eventInfo.onSaleDate).utc().format("x"))
        : null,
      onSaleTime: eventInfo.onSaleTime
        ? Number(moment(eventInfo.onSaleTime).utc().format("x"))
        : null,
      cutOffDateTime:
        eventInfo.cutOffDate && eventInfo.cutOffTime
          ? moment(eventInfo.cutOffDate).format("YYYY-MM-DD") +
            " " +
            moment(eventInfo.cutOffTime).format("HH:mm")
          : null,
      cutOffDate: eventInfo.cutOffDate
        ? Number(moment(eventInfo.cutOffDate).utc().format("x"))
        : null,
      cutOffTime: eventInfo.cutOffTime
        ? Number(moment(eventInfo.cutOffTime).utc().format("x"))
        : null,
      doorTime: eventInfo.doorTime
        ? moment(eventInfo.doorTime).format("YYYY-MM-DD HH:mm:ss")
        : null,
      generalTicketLink: eventInfo.generalTicketLink,
      images: [],
      tickets: eventInfo.tickets,
      preSales: eventInfo.preSales,
      packages: eventInfo.packages,
      media: eventInfo.media,
      venue: eventInfo.venue,
      venueId: eventInfo.venueId,
      venueSittingChartImage: eventInfo.venueSittingChartImage,
      galleryImages: eventInfo.galleryImages,
      startDateTime:
        eventInfo.startDate && eventInfo.startTime
          ? moment(eventInfo.startDate).format("YYYY-MM-DD") +
            " " +
            moment(eventInfo.startTime).format("HH:mm")
          : null,
      endDateTime:
        eventInfo.endDate && eventInfo.endTime
          ? moment(eventInfo.endDate).format("YYYY-MM-DD") +
            " " +
            moment(eventInfo.endTime).format("HH:mm")
          : null,
      bannerImage: eventInfo.bannerImage,
      featureImage: eventInfo.featureImage,
      typeOfPayments: eventInfo.typeOfPayments,
    };

    if (obj.tickets && obj.tickets.length > 0) {
      for (let index = 0; index < obj.tickets.length; index++) {
        const element = obj.tickets[index];

        element.reducePriceDate = element.reducePriceDate
          ? Number(moment(element.reducePriceDate).utc().format("x"))
          : null;
        element.reducePriceTime = element.reducePriceTime
          ? Number(moment(element.reducePriceTime).utc().format("x"))
          : null;

        element.reducePriceDateAndTime =
          element.reducePriceDate && element.reducePriceTime
            ? moment(element.reducePriceDate).format("YYYY-MM-DD") +
              " " +
              moment(element.reducePriceTime).format("HH:mm")
            : null;
      }
    }

    if (obj.preSales && obj.preSales.length > 0) {
      for (let index = 0; index < obj.preSales.length; index++) {
        const element = obj.preSales[index];

        element.startPresaleDate = element.startPresaleDate
          ? Number(moment(element.startPresaleDate).utc().format("x"))
          : null;
        element.startPresaleTime = element.startPresaleTime
          ? Number(moment(element.startPresaleTime).utc().format("x"))
          : null;
        element.endPresaleDate = element.endPresaleDate
          ? Number(moment(element.endPresaleDate).utc().format("x"))
          : null;
        element.endPresaleTime = element.endPresaleTime
          ? Number(moment(element.endPresaleTime).utc().format("x"))
          : null;

        element.startPresaleDateTime =
          element.startPresaleDate && element.startPresaleTime
            ? moment(element.startPresaleDate).format("YYYY-MM-DD") +
              " " +
              moment(element.startPresaleTime).format("HH:mm")
            : null;

        element.endPresaleDateTime =
          element.endPresaleDate && element.endPresaleTime
            ? moment(element.endPresaleDate).format("YYYY-MM-DD") +
              " " +
              moment(element.endPresaleTime).format("HH:mm")
            : null;
      }
    }

    setLoading(true);
    await axiosInstance
      .post(`admin/event`, obj)
      .then((response: any) => {
        setLoading(false);
        if (response?.data?.status) {
          ToastSuccess(response?.data?.message);
          navigate("/events");
        } else {
          ToastFailure(response.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        ToastFailure(error.message);
      });
  };

  // update event
  const updateEvent = async () => {
    const updateObj = {
      _id: id,
      title: eventInfo.title,
      subTitle: eventInfo.subTitle,
      termsAndRules: eventInfo.termsAndRules,
      startDate: eventInfo.startDate
        ? Number(moment(eventInfo.startDate).utc().format("x"))
        : null,
      startTime: eventInfo.startTime
        ? Number(moment(eventInfo.startTime).utc().format("x"))
        : null,
      endDate: eventInfo.endDate
        ? Number(moment(eventInfo.endDate).utc().format("x"))
        : null,
      endTime: eventInfo.endTime
        ? Number(moment(eventInfo.endTime).utc().format("x"))
        : null,
      seoTitle: eventInfo.seoTitle,
      seoDescription: eventInfo.seoDescription,
      description: eventInfo.description,
      isFeatured: eventInfo.isFeatured,
      isPublished: eventInfo.isPublished,
      onSaleDateTime:
        eventInfo.onSaleDate && eventInfo.onSaleTime
          ? moment(eventInfo.onSaleDate).format("YYYY-MM-DD") +
            " " +
            moment(eventInfo.onSaleTime).format("HH:mm")
          : null,
      onSaleDate: eventInfo.onSaleDate
        ? Number(moment(eventInfo.onSaleDate).utc().format("x"))
        : null,
      onSaleTime: eventInfo.onSaleTime
        ? Number(moment(eventInfo.onSaleTime).utc().format("x"))
        : null,
      cutOffDateTime:
        eventInfo.cutOffDate && eventInfo.cutOffTime
          ? moment(eventInfo.cutOffDate).format("YYYY-MM-DD") +
            " " +
            moment(eventInfo.cutOffTime).format("HH:mm")
          : null,
      cutOffDate: eventInfo.cutOffDate
        ? Number(moment(eventInfo.cutOffDate).utc().format("x"))
        : null,
      cutOffTime: eventInfo.cutOffTime
        ? Number(moment(eventInfo.cutOffTime).utc().format("x"))
        : null,
      doorTime: eventInfo.doorTime
        ? moment(eventInfo.doorTime).format("YYYY-MM-DD HH:mm:ss")
        : null,
      generalTicketLink: eventInfo.generalTicketLink,
      images: [],
      tickets: eventInfo.tickets,
      preSales: eventInfo.preSales,
      packages: eventInfo.packages,
      media: eventInfo.media,
      venue: eventInfo.venue,
      venueId: eventInfo.venueId,
      venueSittingChartImage: eventInfo.venueSittingChartImage,
      galleryImages: eventInfo.galleryImages,
      startDateTime:
        eventInfo.startDate && eventInfo.startTime
          ? moment(eventInfo.startDate).format("YYYY-MM-DD") +
            " " +
            moment(eventInfo.startTime).format("HH:mm")
          : null,
      endDateTime:
        eventInfo.endDate && eventInfo.endTime
          ? moment(eventInfo.endDate).format("YYYY-MM-DD") +
            " " +
            moment(eventInfo.endTime).format("HH:mm")
          : null,
      bannerImage: eventInfo.bannerImage,
      featureImage: eventInfo.featureImage,
      typeOfPayments: eventInfo.typeOfPayments,
    };

    if (updateObj.tickets && updateObj.tickets.length > 0) {
      for (let index = 0; index < updateObj.tickets.length; index++) {
        const element = updateObj.tickets[index];

        element.reducePriceDate = element.reducePriceDate
          ? Number(moment(element.reducePriceDate).utc().format("x"))
          : null;
        element.reducePriceTime = element.reducePriceTime
          ? Number(moment(element.reducePriceTime).utc().format("x"))
          : null;

        element.reducePriceDateAndTime =
          element.reducePriceDate && element.reducePriceTime
            ? moment(element.reducePriceDate).format("YYYY-MM-DD") +
              " " +
              moment(element.reducePriceTime).format("HH:mm")
            : null;
      }
    }

    if (updateObj.preSales && updateObj.preSales.length > 0) {
      for (let index = 0; index < updateObj.preSales.length; index++) {
        const element = updateObj.preSales[index];

        element.startPresaleDate = element.startPresaleDate
          ? Number(moment(element.startPresaleDate).utc().format("x"))
          : null;
        element.startPresaleTime = element.startPresaleTime
          ? Number(moment(element.startPresaleTime).utc().format("x"))
          : null;
        element.endPresaleDate = element.endPresaleDate
          ? Number(moment(element.endPresaleDate).utc().format("x"))
          : null;
        element.endPresaleTime = element.endPresaleTime
          ? Number(moment(element.endPresaleTime).utc().format("x"))
          : null;

        element.startPresaleDateTime =
          element.startPresaleDate && element.startPresaleTime
            ? moment(element.startPresaleDate).format("YYYY-MM-DD") +
              " " +
              moment(element.startPresaleTime).format("HH:mm")
            : null;

        element.endPresaleDateTime =
          element.endPresaleDate && element.endPresaleTime
            ? moment(element.endPresaleDate).format("YYYY-MM-DD") +
              " " +
              moment(element.endPresaleTime).format("HH:mm")
            : null;
      }
    }

    setLoading(true);
    await axiosInstance
      .put(`admin/event`, updateObj)
      .then((response: any) => {
        if (response?.data?.status) {
          setLoading(false);

          ToastSuccess(response?.data?.message);
          navigate("/events");
        } else {
          ToastFailure(response.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  function convertToPlain(html: any) {
    // Create a new div element
    const tempDivElement = document.createElement("div");
    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;
    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  // venue change event
  const venueChange = (selectedOption: any) => {
    setSelectedVenueData(selectedOption);
    const eventSpecificPackage = eventInfo.packages.filter((item: any) => {
      return item._id === null;
    });
    setEventInfo({
      ...eventInfo,
      venue: selectedOption.label,
      venueId: selectedOption.value,
      typeOfPayments: selectedOption.typeOfPayments,
      venueSittingChartImage: "",
      packages: eventSpecificPackage || [],
    });
    fetchSavedPackageData(selectedOption.value);
    getSponsorOption(selectedOption.value);
  };

  // functional component for payment types
  function TypeOfPayment(props: any) {
    const { paymentTypes } = props;
    return (
      <div className="pymnt">
        <div className="payments-type">
          <h3 className="accepts">TYPES OF PAYMENT ACCEPTED</h3>
        </div>
        <div className="visa">
          {(paymentTypes || []).map((list: any, i: any) => (
            <span key={i} className="cards">
              {list}
            </span>
          ))}
        </div>
      </div>
    );
  }

  const dateAndTimeCombineHandler = (date: string, time: string) => {
    return (
      moment(date).format("YYYY-MM-DD") + " " + moment(time).format("HH:mm")
    );
  };

  const submitHandler = () => {
    if (eventInfo.title.length === 0) {
      ToastFailure("Tab(General-info):- Please enter title");
      return;
    }

    if (eventInfo.isPublished && eventInfo.subTitle.length === 0) {
      ToastFailure("Tab(General-info):- Please enter sub title");
      return;
    }

    if (eventInfo.isPublished && eventInfo.startDate == null) {
      ToastFailure("Tab(General-info):- Please enter start date");
      return;
    }

    if (eventInfo.isPublished && eventInfo.startTime == null) {
      ToastFailure("Tab(General-info):- Please enter start time");
      return;
    }

    if (eventInfo.isPublished && eventInfo.endDate == null) {
      ToastFailure("Tab(General-info):- Please enter end date");
      return;
    }

    if (eventInfo.isPublished && eventInfo.endTime == null) {
      ToastFailure("Tab(General-info):- Please enter end time");
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.startDate &&
      eventInfo.startTime &&
      moment(new Date()).format("YYYY-MM-DD HH:mm") >=
        dateAndTimeCombineHandler(eventInfo.startDate, eventInfo.startTime)
    ) {
      ToastFailure(
        "Tab(General-info):- Event start date and time should be greater than current date and time"
      );
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.endDate &&
      eventInfo.endTime &&
      moment(new Date()).format("YYYY-MM-DD HH:mm") >=
        dateAndTimeCombineHandler(eventInfo.endDate, eventInfo.endTime)
    ) {
      ToastFailure(
        "Tab(General-info):- Event end date and time should be greater than current date and time"
      );
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.startDate &&
      eventInfo.endDate &&
      eventInfo.startTime &&
      eventInfo.startTime &&
      dateAndTimeCombineHandler(eventInfo.startDate, eventInfo.startTime) >
        dateAndTimeCombineHandler(eventInfo.endDate, eventInfo.startTime)
    ) {
      ToastFailure(
        "Tab(General-info):- Select both Event dates, And Event start date and time should be less than end date and time"
      );
      return;
    }

    if (eventInfo.isPublished && eventInfo.doorTime === null) {
      ToastFailure("Tab(General-info):- Please enter door time");
      return;
    }

    if (eventInfo.isPublished && eventInfo.venueId.length === 0) {
      ToastFailure("Tab(General-info):- Please enter venue");
      return;
    }

    if (
      eventInfo.isPublished &&
      isRichTextContextEmpty(eventInfo.description)
    ) {
      ToastFailure("Tab(General-info):- Please enter description");
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.venueSittingChartImage.length === 0
    ) {
      ToastFailure("Tab(General-info):- Please Select Seating Chart");
      return;
    }

    if (eventInfo.isPublished && eventInfo.bannerImage.length === 0) {
      ToastFailure("Tab(General-info):- Please add banner Image");
      return;
    }

    if (eventInfo.isPublished && eventInfo.featureImage.length === 0) {
      ToastFailure("Tab(General-info):- Please add feature Image");
      return;
    }

    if (
      eventInfo.isPublished &&
      (eventInfo.onSaleTime ||
        eventInfo.cutOffDate ||
        eventInfo.cutOffTime ||
        eventInfo.generalTicketLink) &&
      eventInfo.onSaleDate === null
    ) {
      ToastFailure("Tab(Tickets):- Please enter on sale date");
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.onSaleDate &&
      eventInfo.onSaleTime === null
    ) {
      ToastFailure("Tab(Tickets):- Please enter on sale time");
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.onSaleDate &&
      eventInfo.onSaleTime &&
      moment(new Date()).format("YYYY-MM-DD HH:mm") >=
        dateAndTimeCombineHandler(eventInfo.onSaleDate, eventInfo.onSaleTime)
    ) {
      ToastFailure(
        "Tab(Tickets):- On sale date and time should be greater than current date and time"
      );
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.onSaleDate &&
      eventInfo.cutOffDate === null
    ) {
      ToastFailure("Tab(Tickets):- Please enter on cut-off date");
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.cutOffDate &&
      eventInfo.cutOffTime === null
    ) {
      ToastFailure("Tab(Tickets):- Please enter on cut off time");
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.cutOffDate &&
      eventInfo.cutOffTime &&
      moment(new Date()).format("YYYY-MM-DD HH:mm") >=
        dateAndTimeCombineHandler(eventInfo.cutOffDate, eventInfo.cutOffTime)
    ) {
      ToastFailure(
        "Tab(Tickets):- Cut off date and time should be greater than current date and time"
      );
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.onSaleDate &&
      eventInfo.onSaleTime &&
      eventInfo.cutOffDate &&
      eventInfo.cutOffTime &&
      dateAndTimeCombineHandler(eventInfo.onSaleDate, eventInfo.onSaleTime) >
        dateAndTimeCombineHandler(eventInfo.cutOffDate, eventInfo.cutOffTime)
    ) {
      ToastFailure(
        "Tab(Tickets):- On sale start date and time should be less than cut off date time"
      );
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.onSaleDate &&
      eventInfo.generalTicketLink.length === 0
    ) {
      ToastFailure("Tab(Tickets):- Please enter general ticket link");
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.onSaleDate &&
      eventInfo.generalTicketLink.length > 0 &&
      !validateUrl(eventInfo.generalTicketLink)
    ) {
      ToastFailure("Tab(Tickets):- Please enter valid general ticket link");
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.onSaleDate &&
      eventInfo.tickets.length === 0
    ) {
      ToastFailure("Tab(Tickets):- Please add atlest one ticket");
      return;
    }

    if (
      eventInfo.isPublished &&
      eventInfo.onSaleDate &&
      eventInfo.tickets.length > 0 &&
      isTicketsInvalid()
    ) {
      return;
    }

    if (eventInfo.preSales.length > 0 && isPreSalesInvalid()) {
      return;
    }

    if (eventInfo.packages.length > 0 && isPackageInvalid()) {
      return;
    }

    if (eventInfo.media.length > 0 && isMediaInvalid()) {
      return;
    }

    if (id) {
      updateEvent();
    } else {
      addEvent();
    }
  };

  const isTicketsInvalid = () => {
    let errorCount = 0;
    let errorMessage = "";

    for (let index = 0; index < eventInfo.tickets.length; index++) {
      const element = eventInfo.tickets[index];
      if (element.name.length === 0) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter name at ${numberToWords(
          index + 1
        )} ticket`;
        break;
      }
      if (element.link.length === 0) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter link at ${numberToWords(
          index + 1
        )} ticket`;
        break;
      }
      if (element.link.length > 0 && !validateUrl(element.link)) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter valid link at ${numberToWords(
          index + 1
        )} ticket`;
        break;
      }

      if (element.price.length === 0) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter min price at ${numberToWords(
          index + 1
        )} ticket`;
        break;
      }

      if (element.price1.length === 0) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter max price at ${numberToWords(
          index + 1
        )} ticket`;
        break;
      }

      if (
        element.price &&
        element.price1 &&
        Number(element.price) > Number(element.price1)
      ) {
        errorCount = errorCount + 1;
        errorMessage = `Min price should be less then max price at ${numberToWords(
          index + 1
        )} ticket`;
        break;
      }

      if (element.reducePrice && element.reducePriceDate === null) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter reduce price date at ${numberToWords(
          index + 1
        )} ticket`;
        break;
      }

      if (element.reducePriceDate && element.reducePriceTime === null) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter reduce price time at ${numberToWords(
          index + 1
        )} ticket`;
        break;
      }

      if (
        element.reducePriceDate &&
        element.reducePriceTime &&
        moment(new Date()).format("YYYY-MM-DD HH:mm") >=
          dateAndTimeCombineHandler(
            element.reducePriceDate,
            element.reducePriceTime
          )
      ) {
        errorCount = errorCount + 1;
        errorMessage = `Reduce price date and time should be greater than current date and time at ${numberToWords(
          index + 1
        )} ticket`;
        break;
      }

      if (element.reducePriceDate && element.reducePrice.length === 0) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter reduce price at ${numberToWords(
          index + 1
        )} ticket`;
        break;
      }
    }

    if (errorCount > 0) {
      ToastFailure(`Tab(Tickets):- ${errorMessage}`);
      return true;
    }
    return false;
  };

  const isMediaInvalid = () => {
    let errorCount = 0;
    let errorMessage = "";

    for (let index = 0; index < eventInfo.media.length; index++) {
      const element = eventInfo.media[index];
      if (element.title.length === 0) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter title at ${numberToWords(
          index + 1
        )} media`;
        break;
      }
      if (element.link.length === 0) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter link at ${numberToWords(index + 1)} media`;
        break;
      }
      if (element.link.length > 0 && !validateUrl(element.link)) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter valid link at ${numberToWords(
          index + 1
        )} media`;
        break;
      }

      if (element.type.length === 0) {
        errorCount = errorCount + 1;
        errorMessage = `Please select type at ${numberToWords(
          index + 1
        )} media`;
        break;
      }
    }

    if (errorCount > 0) {
      ToastFailure(`Tab(Media):- ${errorMessage}`);
      return true;
    }
    return false;
  };

  const isPackageInvalid = () => {
    let errorCount = 0;
    let errorMessage = "";

    for (let index = 0; index < eventInfo.packages.length; index++) {
      const element = eventInfo.packages[index];
      if (element.title.length === 0) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter title at ${numberToWords(
          index + 1
        )} package`;
        break;
      }
      if (isRichTextContextEmpty(element.description)) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter description at ${numberToWords(
          index + 1
        )} package`;
        break;
      }
      if (element.ticketLink.length === 0) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter link at ${numberToWords(
          index + 1
        )} package`;
        break;
      }
      if (element.ticketLink.length > 0 && !validateUrl(element.ticketLink)) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter valid link at ${numberToWords(
          index + 1
        )} package`;
        break;
      }

      if (element.price.length === 0) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter price at ${numberToWords(
          index + 1
        )} package`;
        break;
      }
    }

    if (errorCount > 0) {
      ToastFailure(`Tab(Packages):- ${errorMessage}`);
      return true;
    }
    return false;
  };

  const isPreSalesInvalid = () => {
    let errorCount = 0;
    let errorMessage = "";

    for (let index = 0; index < eventInfo.preSales.length; index++) {
      const element = eventInfo.preSales[index];

      if (element.startPresaleDate == null) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter presale start date at ${numberToWords(
          index + 1
        )} presale`;
        break;
      }

      if (element.startPresaleTime == null) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter presale start time at ${numberToWords(
          index + 1
        )} presale`;
        break;
      }

      if (element.endPresaleDate == null) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter presale end date at ${numberToWords(
          index + 1
        )} presale`;
        break;
      }

      if (element.endPresaleTime == null) {
        errorCount = errorCount + 1;
        errorMessage = `Please enter presale end time at ${numberToWords(
          index + 1
        )} presale`;
        break;
      }

      if (
        element.startPresaleDate &&
        element.startPresaleTime &&
        moment(new Date()).format("YYYY-MM-DD HH:mm") >=
          dateAndTimeCombineHandler(
            element.startPresaleDate,
            element.startPresaleTime
          )
      ) {
        errorCount = errorCount + 1;
        errorMessage = `Presale start date and time should be greater than current date and time at ${numberToWords(
          index + 1
        )} presale`;
        break;
      }

      if (
        element.endPresaleDate &&
        element.endPresaleTime &&
        moment(new Date()).format("YYYY-MM-DD HH:mm") >=
          dateAndTimeCombineHandler(
            element.endPresaleDate,
            element.endPresaleTime
          )
      ) {
        errorCount = errorCount + 1;
        errorMessage = `Presale end date and time should be greater than current date and time at ${numberToWords(
          index + 1
        )} presale`;
        break;
      }

      if (
        element.startPresaleDate &&
        element.startPresaleTime &&
        element.endPresaleDate &&
        element.endPresaleTime &&
        dateAndTimeCombineHandler(
          element.startPresaleDate,
          element.startPresaleTime
        ) >
          dateAndTimeCombineHandler(
            element.endPresaleDate,
            element.endPresaleTime
          )
      ) {
        errorCount = errorCount + 1;
        errorMessage =
          "Presale start date and time should be less than end date and time";
        break;
      }
    }

    if (errorCount > 0) {
      ToastFailure(`Tab(Tickets):- ${errorMessage}`);
      return true;
    }
    return false;
  };

  return (
    <>
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <>
          <Loader startLoader={loading} />
          <ChiledHeader
            isAddEvent={isAddEvent}
            eventInfo={eventInfo}
            setIsEventPublish={(value: any) => {
              setEventInfo({
                ...eventInfo,
                isPublished: value,
              });
            }}
          />
          <div className="eventsChilds-container TopCommon MainClass">
            <div className="events-Child EventSec">
              <nav className="nav-content">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className={`nav-link ${
                      isPackageRedirection ? "" : "active"
                    }`}
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected={`${isPackageRedirection ? "false" : "true"}`}
                  >
                    GENERAL INFO
                  </button>
                  <button
                    className="nav-link"
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    TICKETS
                  </button>
                  <button
                    className={`nav-link ${
                      isPackageRedirection ? "active" : ""
                    }`}
                    id="nav-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-contact"
                    type="button"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected={`${isPackageRedirection ? "true" : "false"}`}
                  >
                    PACKAGES
                  </button>
                  <button
                    className="nav-link"
                    id="nav-media-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-media"
                    type="button"
                    role="tab"
                    aria-controls="nav-media"
                    aria-selected="false"
                  >
                    MEDIA
                  </button>
                  <button
                    className="nav-link"
                    id="nav-terms-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-terms"
                    type="button"
                    role="tab"
                    aria-controls="nav-terms"
                    aria-selected="false"
                  >
                    TERMS/RULES
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className={`tab-pane fade  ${
                    isPackageRedirection ? "" : "show active"
                  }`}
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="event-Genral-info">
                          <div className="info-genral">
                            <span className="CommonLabel">TITLE</span>
                            <div className="inputField">
                              <input
                                type="text"
                                maxLength={50}
                                className="genral-Ryan"
                                onChange={(e: any) => {
                                  setEventInfo({
                                    ...eventInfo,
                                    title: e.target.value,
                                  });
                                }}
                                value={eventInfo.title}
                              />
                              <span
                                className={`genr-btn ${
                                  eventInfo.isFeatured
                                    ? "activeFeature"
                                    : "deactiveFeature"
                                }`}
                                onClick={() => {
                                  const flag =
                                    eventInfo.isFeatured === 0 ? 1 : 0;
                                  setEventInfo({
                                    ...eventInfo,
                                    isFeatured: flag,
                                  });
                                }}
                              >
                                <span className="rayn-btn">
                                  <span className="featured">
                                    <img src={featured} alt="/" />
                                    <span className="grnt">FEATURED</span>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="Subtitle">
                            <div className="sprint">
                              <span className="CommonLabel">SUBTITLE</span>
                              <div className="textArea">
                                <div className="form-floating">
                                  <textarea
                                    className="form-control"
                                    onChange={(e: any) => {
                                      setEventInfo({
                                        ...eventInfo,
                                        subTitle: e.target.value,
                                      });
                                    }}
                                    value={eventInfo.subTitle}
                                    id="floatingTextarea2"
                                    style={{ height: "150px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="booking-event">
                            <div className="reserve-date-time">
                              <div>
                                <span className="CommonLabel">EVENT START</span>
                                <DatePicker
                                  className="calenderIcon"
                                  autoComplete="off"
                                  selected={eventInfo.startDate}
                                  onChange={(date) => {
                                    setEventInfo({
                                      ...eventInfo,
                                      startDate: date,
                                    });
                                  }}
                                  dateFormat="MM/dd/y"
                                  minDate={moment().toDate()}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="control-pane default datePicker">
                                <span className="CommonLabel">START TIME</span>
                                <DatePicker
                                  className="timeIcon"
                                  autoComplete="off"
                                  selected={eventInfo.startTime}
                                  onChange={(date) => {
                                    setEventInfo({
                                      ...eventInfo,
                                      startTime: date,
                                    });
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                />
                              </div>
                            </div>
                            <div className="event-end">
                              <div>
                                <span className="CommonLabel">EVENT END</span>
                                <DatePicker
                                  className="calenderIcon"
                                  autoComplete="off"
                                  selected={eventInfo.endDate}
                                  onChange={(date) => {
                                    setEventInfo({
                                      ...eventInfo,
                                      endDate: date,
                                    });
                                  }}
                                  dateFormat="MM/dd/y"
                                  minDate={moment().toDate()}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="control-pane default">
                                <span className="CommonLabel">END TIME</span>
                                <DatePicker
                                  className="timeIcon"
                                  autoComplete="off"
                                  selected={eventInfo.endTime}
                                  onChange={(date) => {
                                    setEventInfo({
                                      ...eventInfo,
                                      endTime: date,
                                    });
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="door-time">
                            <span className="CommonLabel">
                              DOOR TIME
                              <DatePicker
                                autoComplete="off"
                                selected={eventInfo.doorTime}
                                className="doorTimeField timeIcon"
                                onChange={(date) => {
                                  setEventInfo({
                                    ...eventInfo,
                                    doorTime: date,
                                  });
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={1}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                              />
                            </span>
                          </div>
                          <div className="seo-concert">
                            <span className="CommonLabel">SEO</span>
                            <div className="seo-input">
                              <input
                                type="text"
                                className="seo-name-concert"
                                onChange={(e: any) => {
                                  setEventInfo({
                                    ...eventInfo,
                                    seoTitle: e.target.value,
                                  });
                                }}
                                value={eventInfo.seoTitle}
                                placeholder="Title"
                              />
                            </div>
                            <div className="seo-input seoInputField">
                              <input
                                type="text"
                                className="seo-name-concert"
                                onChange={(e: any) => {
                                  setEventInfo({
                                    ...eventInfo,
                                    seoDescription: e.target.value,
                                  });
                                }}
                                value={eventInfo.seoDescription}
                                placeholder="Description"
                              />
                            </div>
                          </div>
                          <div className="venue">
                            <span className="CommonLabel">VENUE</span>
                            <Select
                              name="venue"
                              value={selectedVenueData}
                              onChange={(selectedOption: any) => {
                                selectedVenueData == null
                                  ? venueChange(selectedOption)
                                  : ConfirmVenueChange(
                                      selectedOption,
                                      (selectedOption: any) => {
                                        venueChange(selectedOption);
                                      }
                                    );
                              }}
                              options={venueDataOptions}
                            />
                          </div>
                          <div className="Description">
                            <label className="description-online-editer CommonLabel">
                              DESCRIPTION
                            </label>
                            <div className="Editors">
                              <RichTag
                                editerDetails={eventInfo.description}
                                setEditerDetails={(value: any) => {
                                  setEventInfo({
                                    ...eventInfo,
                                    description: value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="rightSide-part">
                          <div className="images">
                            {eventInfo.featureImage && (
                              <img
                                src={eventInfo.featureImage}
                                className="concerts-img"
                                alt="feature_image"
                              />
                            )}
                          </div>
                          <div className="EventImagesSmall">
                            {eventInfo?.galleryImages?.length > 0 &&
                              (eventInfo.galleryImages || []).map(
                                (list: any, index: any) => (
                                  <img
                                    key={index}
                                    src={list}
                                    alt="gallery_image"
                                  />
                                )
                              )}
                          </div>
                          <div className="upload-btn">
                            <Button
                              className="uploadbtn"
                              onClick={() => setModalShow(true)}
                            >
                              UPLOAD IMAGES
                            </Button>
                            {modalShow && (
                              <UploadEventImage
                                bannerImage={eventInfo.bannerImage}
                                featureImage={eventInfo.featureImage}
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                setCroppedBannerCloud={(e: any) => {
                                  setEventInfo({
                                    ...eventInfo,
                                    bannerImage: e,
                                  });
                                }}
                                setCroppedFeature={(e: any) => {
                                  setEventInfo({
                                    ...eventInfo,
                                    featureImage: e,
                                  });
                                }}
                                galleryImages={eventInfo.galleryImages}
                                setGalleryImages={(e: any) => {
                                  setEventInfo({
                                    ...eventInfo,
                                    galleryImages: e,
                                  });
                                }}
                              />
                            )}
                          </div>
                          {selectedVenueData &&
                            Object.keys(selectedVenueData).length > 0 && (
                              <div className="seating-chart">
                                <span className="select-seating">
                                  SELECT SEATING CHART
                                </span>
                                {(
                                  selectedVenueData?.sittingChartImage || []
                                ).map((list: any, index: any) => {
                                  return (
                                    <div
                                      key={index}
                                      className={
                                        eventInfo.venueSittingChartImage ===
                                        list
                                          ? "general activeSitting"
                                          : "general"
                                      }
                                      onClick={() => {
                                        setEventInfo({
                                          ...eventInfo,
                                          venueSittingChartImage: list,
                                        });
                                      }}
                                    >
                                      <div className="admision">
                                        <div className="admision-charts">
                                          <img
                                            src={list}
                                            className="seating-images"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="tickets-bookings">
                          <div className="tickets-avila">
                            <div className="book">
                              <div className="reserve-date-time">
                                <div>
                                  <span className=" CommonLabel">ON SALE</span>
                                  <DatePicker
                                    className="calenderIcon"
                                    autoComplete="off"
                                    selected={eventInfo.onSaleDate}
                                    onChange={(date) => {
                                      setEventInfo({
                                        ...eventInfo,
                                        onSaleDate: date,
                                      });
                                    }}
                                    dateFormat="MM/dd/y"
                                    minDate={moment().toDate()}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                </div>
                                <div className="control-pane default">
                                  <span className="CommonLabel">
                                    START TIME
                                  </span>
                                  <DatePicker
                                    className="timeIcon"
                                    autoComplete="off"
                                    selected={eventInfo.onSaleTime}
                                    onChange={(date) => {
                                      setEventInfo({
                                        ...eventInfo,
                                        onSaleTime: date,
                                      });
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={1}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                  />
                                </div>
                              </div>
                              <div className="cut-off">
                                <div>
                                  <span className=" CommonLabel">CUT OFF</span>
                                  <DatePicker
                                    className="calenderIcon"
                                    autoComplete="off"
                                    selected={eventInfo.cutOffDate}
                                    onChange={(date) => {
                                      setEventInfo({
                                        ...eventInfo,
                                        cutOffDate: date,
                                      });
                                    }}
                                    dateFormat="MM/dd/y"
                                    minDate={moment().toDate()}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                </div>
                                <div className="control-pane default">
                                  <span className="CommonLabel">END TIME</span>
                                  <DatePicker
                                    className="timeIcon"
                                    autoComplete="off"
                                    selected={eventInfo.cutOffTime}
                                    onChange={(date) => {
                                      setEventInfo({
                                        ...eventInfo,
                                        cutOffTime: date,
                                      });
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={1}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="generic">
                              <div className="genral-tickets">
                                <label className="CommonLabel">
                                  GENERAL TICKETS LINK
                                </label>
                              </div>
                              <div className="genral-link">
                                <input
                                  type="text"
                                  className="link"
                                  onChange={(e: any) => {
                                    setEventInfo({
                                      ...eventInfo,
                                      generalTicketLink: e.target.value,
                                    });
                                  }}
                                  value={eventInfo.generalTicketLink}
                                />
                              </div>
                            </div>
                            <AddTicket
                              values={eventInfo.tickets}
                              setValues={(value: any) => {
                                setEventInfo({
                                  ...eventInfo,
                                  tickets: value,
                                });
                              }}
                              sponsorOption={sponsorOption}
                            />
                            <AddPresale
                              values={eventInfo.preSales}
                              setValues={(value: any) => {
                                setEventInfo({
                                  ...eventInfo,
                                  preSales: value,
                                });
                              }}
                              sponsorOption={sponsorOption}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="charts-seating">
                          {eventInfo.venueSittingChartImage !== "" && (
                            <div className="setings">
                              <span className="seat-name">SEATING CHART</span>
                              <div className="seating-chart-images">
                                <img
                                  src={eventInfo.venueSittingChartImage}
                                  className="seat-charts"
                                  alt="/"
                                />
                              </div>
                            </div>
                          )}

                          <div className="type-of-payments">
                            {eventInfo?.typeOfPayments &&
                              eventInfo?.typeOfPayments.length > 0 && (
                                <TypeOfPayment
                                  paymentTypes={eventInfo?.typeOfPayments}
                                />
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade  ${
                    isPackageRedirection ? "show active" : ""
                  }`}
                  id="nav-contact"
                  role="tabpanel"
                  aria-labelledby="nav-contact-tab"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="addPakages-btn" />
                      </div>
                      <AddPackageToEvent
                        values={eventInfo.packages}
                        setValues={(value: any) => {
                          setEventInfo({
                            ...eventInfo,
                            packages: value,
                          });
                        }}
                        packageList={packageOption}
                        venueName={eventInfo.venue}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-media"
                  role="tabpanel"
                  aria-labelledby="nav-media-tab"
                >
                  <div className="media-container">
                    <div className="media">
                      <AddMedia
                        values={eventInfo.media}
                        setValues={(value: any) => {
                          setEventInfo({
                            ...eventInfo,
                            media: value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-terms"
                  role="tabpanel"
                  aria-labelledby="nav-terms-tab"
                >
                  <div className="selection-items">
                    <select
                      className="selected-points"
                      onChange={(e: any) => {
                        setEventInfo({
                          ...eventInfo,
                          termsAndRules: e.target.value,
                        });
                        const index = e.target.selectedIndex;
                        const el: any = e.target.childNodes[index];
                        const getId = el.getAttribute("id");
                        setSelectedTeamsAndRulesID(getId);
                      }}
                      value={eventInfo.termsAndRules}
                    >
                      <option value="" className="existingsfile">
                        Choose Existing templets
                      </option>
                      {teamsAndRulesOption.map((list: any, index) => (
                        <option
                          className="existingsfile"
                          key={index}
                          value={list.termsAndRules}
                          id={list._id}
                        >
                          {convertToPlain(list.termsAndRules)}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="textEditer-area">
                    <div className="rule">
                      <label className="CommonLabel" htmlFor="/">
                        RULES
                      </label>
                    </div>
                    <div className="text-editer-terms">
                      <RichTag
                        editerDetails={eventInfo.termsAndRules}
                        setEditerDetails={(value: any) => {
                          setEventInfo({
                            ...eventInfo,
                            termsAndRules: value,
                          });
                        }}
                      />
                    </div>
                    <div className="save-as-templet-btn save_delete_button">
                      <button
                        type="button"
                        className="save-template"
                        onClick={() => saveTermsAndRules()}
                      >
                        <img src={save} alt="/" className="save-templets-img" />
                        <span className="as-a">SAVE AS A TEMPLATE</span>
                      </button>
                      <button
                        type="button"
                        className="delete_button"
                        onClick={() => deleteTemplate()}
                      >
                        <img src={del} alt="/" className="save-templets-img" />
                        <span className="as-a">DELETE TEMPLATE</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer
              footerDeleteEvent={() => ConfirmDelete(id, deleteEvent)}
              isAddEvent={isAddEvent}
              isSubmit={submitHandler}
              cancelSubmit={() => {
                navigate("/events");
              }}
            />
          </div>
        </>
      )}
    </>
  );
}

export default EventChild;
